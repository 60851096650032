import React, { useEffect, useState } from "react";
import { Map, Placemark, SearchControl, YMaps } from "@pbe/react-yandex-maps";
import MainButton from "../Common/MainButton";
import YMapsMap from "./YMapsMap";

const PostFields = ({
  phone,
  setPhone,
                      setIsOutsideMkad,
  postcode,
  setComment,
  comment,
  setPostcode,
  fullname,
  setFullname,
  address,
  changePromo,
  isPromo,
  togglePromo,
  setIsInsideMkad,
  isFreeDelivery,
  promo,
  totalQuantity,
  isPromoActivated,
  setAddress,
  setGeol,
  geo
}) => {
  return (
    <div className="delivery-hidden">
      <div className="delivery-address">
        <input
          placeholder="Ф.И.О (полностью)"
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />
        <YMapsMap setAddress={setAddress} address={address} setIsOutsideMkad={setIsOutsideMkad} setIsMkad={setIsInsideMkad} setGeol={setGeol} geol={geo}/>
        <input
          placeholder="Индекс:"
          value={postcode}
          onChange={(e) => setPostcode(e.target.value.trim())}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />
        <input
          placeholder="Тел:"
          value={phone}
          onChange={(e) => setPhone(e.target.value.trim())}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />

        <input
          placeholder="Комментарий:"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />
        <p
          onClick={togglePromo}
          className="mt-2"
          style={{ cursor: "pointer", paddingBottom: 0, marginBottom: 0, textDecoration: "underline" }}
        >
          Есть промокод?
        </p>
        <div
          className={`${
            isPromo ? "d-flex" : "d-none"
          } justify-content-between align-items-end gap-4`}
        >
          <input
            placeholder="Промокод:"
            className="w-50"
            onChange={(e) => {
              changePromo(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.target.blur();
              }
            }}
          />
          {promo !== "" && (
            <p
              className={`${
                isFreeDelivery && !isPromoActivated
                  ? totalQuantity >= 5
                    ? "text-success"
                    : "text-danger"
                  : "text-danger"
              } mb-0 pb-0`}
              style={{ fontSize: "13px" }}
            >
              {isFreeDelivery
                ? totalQuantity >= 5
                  ? !isPromoActivated
                    ? "Верный промокод"
                    : "Действует только на первый заказ"
                  : "Минимальное количество товаров - 5 шт"
                : isPromoActivated
                ? "Действует только на первый заказ"
                : "Неверный промокод"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostFields;

import * as React from "react";
import {useEffect, useState} from "react";
import fetchData from "../../Lib/fetchData";
import {Link} from "react-router-dom";

const monthNames = ["январь", "февраль", "март", "апрель", "май", "июнь",
    "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"
];


function CourierItem({ date, month, couriers }) {
    return (
        <section className="courier-section">
            <header className="courier-header">
                <h2 className="courier-date">{date} {monthNames[month]}</h2>
                <a href="#" className="courier-all-link">см. все</a>
            </header>
            <ul className="courier-list">
                {couriers.map((courier, index) => (
                    <Link to={`${courier.shiftId}`} key={index} className="courier-item text-black">{courier.name}</Link>
                ))}
            </ul>
        </section>
    );
}

function TotalShifts() {
    const [datesAndCouriers, setDatesAndCouriers] = useState([]);
    const [month, setMonth] = useState(new Date().getMonth());

    useEffect(() => {
        const getData = async () => {
            const res = await fetchData(`/shifts/2024/${month + 1}`, 'GET', undefined, true);

            setDatesAndCouriers(await res.json());
        }

        getData();
    }, [month]);

    return (
        <>
            <div className="main-container">
                <header className="top-bar">
                    <div className="header-lines">
                        <div className="fw-bold" style={{cursor: 'pointer'}} onClick={() => setMonth(month === 0 ? 11 : month - 1)}> {"<"}</div>
                    </div>
                    <h1 className="main-title">{monthNames[month]} 2024</h1>
                    <div className="header-lines">
                        <div className="fw-bold" style={{cursor: 'pointer'}} onClick={() => setMonth(month === 11 ? 0 : month + 1)}> > </div>
                    </div>
                </header>
                <main className="content">
                    {datesAndCouriers.map((item, index) => (
                        <CourierItem key={index} date={item.date} couriers={item.couriers} month={month} />
                    ))}
                </main>
            </div>
            <style jsx>{`
        .main-container {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          padding-bottom: 47px;
        }

        .top-bar {
          background-color: #b5b5b5;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 40px 10px;
        }
        .header-lines {
          display: flex;
          flex-direction: column;
          margin: auto 0;
        }

        .header-line {
          border: 2px solid rgba(101, 101, 101, 1);
          background-color: #656565;
        }

        .header-line:nth-child(1) {
          width: 11px;
          height: 11px;
        }

        .header-line:nth-child(2) {
          width: 10px;
          height: 10px;
        }

        .main-title {
          color: #000;
          text-align: center;
          flex-grow: 1;
          font: 600 32px/25% Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .content {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 10px;
            text-align: center;
            padding: 0 20px;
            margin-top: 19px;
        }

        /* Tablet view */
        @media (max-width: 768px) {
            .content {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }

        /* Phone view */
        @media (max-width: 480px) {
            .content {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }

        .courier-section {
          margin: 13px 0;
          flex: 1;
        }

        .courier-header {
          background-color: #8f8f8f;
          display: flex;
          justify-content: space-between;
          padding: 13px 15px;
          border: 1px solid rgba(109, 109, 109, 1);
        }

        .courier-date {
          font: 600 14px/57% Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
          color: #000;
        }

        .courier-all-link {
          color: #ff9400;
          font: 400 10px/120% Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
        }

        .courier-list {
          display: flex;
          flex-direction: column;
          font-size: 11px;
          color: #000;
          font-weight: 400;
          line-height: 73%;
          padding: 1px 0;
          border: 1px solid rgba(109, 109, 109, 1);
          background-color: #fff;
        }

        .courier-item {
          background-color: #d9d9d9;
          padding: 6px 60px;
          border-bottom: 1px solid rgba(109, 109, 109, 1);
        }


        .courier-item:last-child {
          border-bottom: none;
        }
      `}</style>
        </>
    );
}

export default TotalShifts;
import React, { useEffect, useState } from "react";
import fetchData from "../../Lib/fetchData";
import BrandPriority from "../../Components/Admin/BrandPriority";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

const AdminBrands = () => {
  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [filter, setFilter] = useState("");

  const changeBrandPriority = (brand, priority) => {
    const i = brands.findIndex((brnd) => brnd.brand === brand);
    brands[i].brandPriority = priority;
    setBrands(brands);
  };

  const saveBrandPriority = async () => {
    let newBrands = brands;
    const res = await fetchData("/admin/brands", "PATCH", brands, true);
    if (res.status === 200) {
      toast("Последовательность брендов успешно изменена");
    } else {
      toast.error("Произошла ошибка");
    }
  };

  useEffect(() => {
    const getBrands = async () => {
      const res = await fetchData("/stuff/brands/" + category, "GET");
      if (res.status === 200) {
        if (category === "snus") {
          if (filter === "tobacco") {
            setBrands(
              (await res.json()).filter((brand) => brand.isTobacco === true)
            );
          } else if (filter === "non-tobacco") {
            setBrands(
              (await res.json()).filter((brand) => brand.isTobacco === false)
            );
          }
        } else {
          setBrands(await res.json());
        }
      }
    };

    if (category !== "") getBrands();
  }, [category, filter]);

  return (
    <>
      <div className="mx-4">
        <h1>Редактирование порядка брендов</h1>
        <select
          className="form-select mb-2 w-auto"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="category" selected>
            Категория
          </option>
          <option value="snus">Снюс</option>
          <option value="pods">Поды</option>
          <option value="liquids">Жижа</option>
          <option value="devices">Устройства</option>
        </select>
        {category === "snus" && (
          <select
            className="form-select mb-2 w-auto"
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="" selected>
              Фильтр
            </option>
            <option value="tobacco">Табачный</option>
            <option value="non-tobacco">Бестабачный</option>
          </select>
        )}
        <div>
          {brands.map((brand) => (
            <BrandPriority
              changeBrandPriority={changeBrandPriority}
              brand={brand.brand}
              brandPriority={brand.brandPriority}
              key={brand.brand}
            />
          ))}
        </div>
        <Button variant="primary" onClick={saveBrandPriority}>
          Сохранить
        </Button>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminBrands;

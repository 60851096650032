import React, { useEffect, useState } from "react";
import Header from "../Components/Common/Header";
import { Container } from "react-bootstrap";
import FlexElement from "../Components/UI/FlexElement";
import addressesSvg from "../images/icons/Addresses.svg";
import fetchData from "../Lib/fetchData";
import { useNavigate } from "react-router-dom";
import Loader3Packs from "./Loaders/Loader3Packs";

const Addresses = () => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const getAddresses = async () => {
    const addrs = await fetchData("/address", "GET").then((res) =>
        res.json()
    );
    setAddresses(addrs.reverse());
    setLoading(false);
  };

  useEffect(() => {

    getAddresses();
  }, []);

  if (loading) {
    return <Loader3Packs />;
  }

  return (
      <>
        <Header title="Адреса" />
        <Container fluid className="mt-3">
          <div className="settings-list">
            {addresses.length !== 0 &&
                addresses.map((addr) => (
                    <FlexElement
                        key={addr._id}
                        text={addr.address}
                        svg={addressesSvg}
                        description={addr.fullName}
                        svgDelete={'/delete.png'}
                        id={addr._id}
                        rerender={getAddresses}
                    />
                ))}
          </div>
        </Container>
      </>
  );
};

export default Addresses;
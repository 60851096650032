import React, { useContext } from "react";
import { CartContext } from "../../Context/CartContext";

const StuffButton = ({
  stuff,
  quantity,
  addToQuantity,
  removeFromQuantity,
  setQuantity
}) => {
  const { dispatch } = useContext(CartContext);

  if (quantity === 0) {
    return (
      <button
        className="stuff-button mb-4"
        onClick={() => {
          dispatch({ payload: { ...stuff, quantity: 1 }, type: "add" });
          addToQuantity();
        }}
      >
        Добавить
      </button>
    );
  } else {
    return (
      <div className="button-change-quantity mb-4">
        <i
          className="bi bi-dash-lg"
          onClick={() => {
            dispatch({ payload: stuff, type: "remove" });
            removeFromQuantity();
          }}
        ></i>
        {quantity}
        <i
          className="bi bi-plus-lg"
          onClick={() => {
            dispatch({ payload: stuff, type: "add" });
            addToQuantity();
          }}
        ></i>
      </div>
    );
  }
};

export default StuffButton;

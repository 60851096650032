import { io } from 'socket.io-client';

const URL = 'https://ngsserver.onrender.com/';

export const socket = io(URL, {
  path: '/socket.io',
  transports: ['websocket'],
  secure: true,
  port: 8080,
  host: 'https://ngsserver.onrender.com/',
  hostname: 'https://ngsserver.onrender.com/'
});


import React, { useContext } from "react";
import { CartContext } from "../../Context/CartContext";
import { Col, Container, Row } from "react-bootstrap";

const CartElement = ({ stuff, isFromCart, totalQuantity }) => {
  const { dispatch } = useContext(CartContext);

  return (
    <Row className="cart-elem">
      <Col className="col-3 px-0">
        <div className="img-cart-elem-div position-relative">
          <img
            src={`${process.env.REACT_APP_AWS_URL}/images/${stuff.image}`}
            alt="Img"
            className="img-elem position-absolute top-50 start-50 translate-middle"
          />
        </div>
      </Col>
      <Col className="col-9 px-0 cart-elem-title position-relative">
        {stuff.title}
        <p className="cart-description">{stuff.description}</p>
        {isFromCart ? (
          <>
            <div
              className="position-absolute top-0 end-0"
              onClick={() => dispatch({ payload: stuff, type: "delete" })}
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-x-lg x-cart-elem"></i>
            </div>

            <div>
              <div className="btn-change-quantity-cart">
                <i
                  className="bi bi-dash-lg"
                  onClick={() => dispatch({ payload: stuff, type: "remove" })}
                ></i>
                <input
                  type="number"
                  value={Number(stuff.quantity).toString()}
                  onBlur={() => {
                    if(Number(stuff.quantity) === 0)
                      dispatch({ payload: stuff, type: "remove" });
                  }}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                      dispatch({
                        payload: {...stuff, quantity: Number(e.target.value)},
                        type: "update_quantity",
                      });
                    }
                    else {
                      dispatch({ payload: stuff, type: "remove" });
                    }
                  }}
                  style={{
                    width: "30px",
                    background: "none",
                    outline: "none",
                    border: "none",
                    textAlign: "center",
                  }}
                />
                <i
                  className="bi bi-plus-lg"
                  onClick={() => dispatch({ payload: stuff, type: "add" })}
                ></i>
              </div>
            </div>
          </>
        ) : (
          <div className="position-absolute bottom-0 start-0 order-quantity">
            <span>{stuff.quantity} шт.</span>
          </div>
        )}

        {totalQuantity < 5 ? (
          <div className="position-absolute bottom-0 end-0">
            {stuff.discount
              ? (stuff.priceFromOne - stuff.discount) * stuff.quantity
              : stuff.priceFromOne * stuff.quantity}{" "}
            ₽
          </div>
        ) : totalQuantity < 10 ? (
          <div className="position-absolute bottom-0 end-0">
            <span className="text-grey crossed">
              {stuff.priceFromOne * stuff.quantity} ₽
            </span>{" "}
            {stuff.discount
              ? (stuff.priceFromOne - stuff.discount) * stuff.quantity
              : stuff.priceFromFive * stuff.quantity}{" "}
            ₽
          </div>
        ) : (
          <div className="position-absolute bottom-0 end-0">
            <span className="text-grey crossed">
              {stuff.priceFromOne * stuff.quantity} ₽
            </span>{" "}
            {stuff.discount
              ? (stuff.priceFromOne - stuff.discount) * stuff.quantity
              : stuff.priceFromTen * stuff.quantity}{" "}
            ₽
          </div>
        )}
      </Col>
    </Row>
  );
};

export default CartElement;

import React from "react";
import { Container } from "react-bootstrap";
import LeftArrow from "../UI/LeftArrow";

const Header = ({ title }) => {
  return (
    <Container fluid className="px-0 cart-header-div">
      <span className="cart-header">{title}</span>
    </Container>
  );
};

export default Header;

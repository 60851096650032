import React, {useContext} from 'react';
import {CartContext} from "../../Context/CartContext";
import {Link} from "react-router-dom";
const AnchorBasket = () => {
  const { cart } = useContext(CartContext);

  if(cart.length > 0)
  return (
    <Link to="/cart" className="anchor-basket">
      <div className="position-relative w-100 h-100">
        <div className="position-absolute top-50 start-50 translate-middle" style={{color: "#FF9400", zIndex: 100, marginTop: "5px", fontWeight: 600}}>{cart.length}</div>
        <img className="position-absolute top-50 start-50 translate-middle" width={40} height={40} src="/anchorBasket.svg" />
      </div>
    </Link>
  );
};

export default AnchorBasket;
import React, { createContext, useState } from "react";

const HistoryContext = createContext({});

const HistoryContextProvider = (props) => {
  const [path, setPath] = useState("");

  const setHistoryPath = (newPath) => {
    setPath(newPath);
  };

  return (
    <HistoryContext.Provider
      value={{
        path,
        setHistoryPath,
      }}
    >
      {props.children}
    </HistoryContext.Provider>
  );
};

export { HistoryContext, HistoryContextProvider };

import React, { useContext } from "react";
import { CartContext } from "../../../Context/CartContext";
import "./cart-svg.css";

const CartSvg = () => {
  const { cart } = useContext(CartContext);

  return (
    <div className="position-relative">
      <svg width="20" height="20" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.5 18C21.5 15.2152 22.6062 12.5445 24.5753 10.5754C26.5445 8.60625 29.2152 7.5 32 7.5C34.7847 7.5 37.4554 8.60625 39.4246 10.5754C41.3937 12.5445 42.5 15.2152 42.5 18H21.5ZM14.5 18C14.5 13.3587 16.3437 8.90752 19.6256 5.62563C22.9075 2.34374 27.3587 0.5 32 0.5C36.6412 0.5 41.0924 2.34374 44.3743 5.62563C47.6562 8.90752 49.5 13.3587 49.5 18H60C60.4862 18 60.967 18.1012 61.4119 18.2974C61.8568 18.4935 62.256 18.7801 62.5839 19.139C62.9119 19.498 63.1615 19.9213 63.3168 20.382C63.4721 20.8427 63.5297 21.3308 63.486 21.815L60.5635 53.952C60.326 56.5608 59.1219 58.9866 57.1876 60.7531C55.2533 62.5197 52.7285 63.4994 50.109 63.5H13.891C11.2714 63.4994 8.74662 62.5197 6.81234 60.7531C4.87806 58.9866 3.67397 56.5608 3.43646 53.952L0.51396 21.815C0.470202 21.3308 0.527811 20.8427 0.683115 20.382C0.838419 19.9213 1.08801 19.498 1.41598 19.139C1.74395 18.7801 2.1431 18.4935 2.588 18.2974C3.03289 18.1012 3.51376 18 3.99996 18H14.5Z"
      fill={
            window.location.pathname === "/stuff"
              ? "black"
              : "var(--cart-fill-color)"
          }
          fillOpacity={window.location.pathname === "/stuff" ? "0.74" : "0.6"}
        />
      </svg>
      {cart.length > 0 && <span className="cart-size">{cart.length}</span>}
    </div>
  );
};

export default CartSvg;

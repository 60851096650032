import React from "react";

const CheckoutOrder = ({ price, promocodeUsed }) => {
  return (
    <div className="checkout">
      <div className="checkout-elem">
        <span>Сумма заказа:</span>
        <span>{price.originalPrice} ₽</span>
      </div>
      <div className="checkout-elem">
        <span>Доставка:</span>
        {promocodeUsed === "NGSFREE" ? (
          <span><s>{price.discountByPromo} ₽</s></span>) :
          <span>{price.deliveryPrice} ₽</span>}
      </div>
      {
        promocodeUsed === "NGS10" && (
          <div className="checkout-elem">
            <span>Промокод:</span>
            <span>-{price.discountByPromo} ₽</span>
          </div>
        )
      }
      {price.discount !== 0 && (
        <>
          <div className="checkout-elem">
            <span>Скидка:</span>
            <span style={{textDecoration: 'none'}}>-{price.discountByQuantity} ₽</span>
          </div>
        </>
      )}

      <div className="checkout-elem checkout-elem-total">
        <span>Итого:</span>
        <span>{price.totalPrice} ₽</span>
      </div>
    </div>
  );
};

export default CheckoutOrder;

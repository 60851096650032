import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import UserRow from "../../Components/Admin/UserRow";
import fetchData from "../../Lib/fetchData";

const EditUsers = () => {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const start = async () => {
      const res = await fetchData("/admin/users", "GET", {}, true);
      setUsers(await res.json());
    };

    start();
  }, []);

  const getUserById = async() => {
    setUsers(prevState => prevState.filter(user => user.userId === parseInt(userId)));
  };

  const getUserByUsername = async () => {
    setUsers(prevState => prevState.filter(user => user.username === username));
  }

  return (
    <>
      <div className="text-center p-3">
        <p className="h3">Просмотр пользователей</p>
        <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder="Username" aria-label="Username"
                 aria-describedby="basic-addon2" onChange={e => setUsername(e.target.value)} />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={getUserByUsername}>Поиск</button>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder="User ID" aria-label="User ID"
                 aria-describedby="basic-addon2" onChange={e => setUserId(e.target.value)} />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={getUserById}>Поиск</button>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Телеграм ID</th>
              <th>Имя</th>
              <th>Фамилия</th>
              <th>Имя пользователя</th>
              <th>Номер телефона</th>
              <th>Секретный токен</th>
              <th>Дата регистрации</th>
            </tr>
          </thead>
          <tbody>
            {users.length !== 0 &&
              users.map((user) => (
                <UserRow
                  id={user.userId}
                  name={user.firstName}
                  surname={user.lastName}
                  username={user.username}
                  secret={user.lostAccessToken}
                  telephone={user.phone}
                  registrationDate={`${new Date(user.registeredAt).toLocaleDateString()} ${new Date(user.registeredAt).toLocaleTimeString()}`}
                />
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default EditUsers;

import { Route } from "react-router-dom";
import Index from "../Pages/Index/Index";
import React from "react";
import Shop from "../Pages/Shop/Shop";
import Stuff from "../Pages/Stuff/Stuff";
import Cart from "../Pages/Cart/Cart";
import DeliveryTerms from "../Pages/DeliveryTerms/DeliveryTerms";
import Promotions from "../Pages/Promotions";
import Novelties from "../Pages/Novelties";
import Profile from "../Pages/Profile/Profile";
import Settings from "../Pages/Settings/Settings";
import Addresses from "../Pages/Addresses";
import Orders from "../Pages/Orders";
import Order from "../Pages/Order";
import Categories from "../Pages/Categories";
import AdminLogin from "../Pages/Admin/AdminLogin";
import Dashboard from "../Pages/Admin/Dashboard";
import AddStuff from "../Pages/Admin/AddStuff";
import EditStuff from "../Pages/Admin/EditStuff";
import EditUsers from "../Pages/Admin/EditUsers";
import AdminOrders from "../Pages/Admin/AdminOrders";
import ProtectedRoute from "../Components/Admin/ProtectedRoute";
import OrderCreated from "../Pages/OrderCreated/OrderCreated";
import AdminBrands from "../Pages/Admin/AdminBrands";
import AdminSnusBanner from "../Pages/Admin/AdminSnusBanner";
import AdminModels from "../Pages/Admin/AdminModels";
import AdminOrder from "../Pages/Admin/AdminOrder";
import Promocode from "../Pages/Promocode";
import AdminVapeBanner from "../Pages/Admin/AdminVapeBanner";
import NoveltiesVapes from "../Pages/NoveltiesVapes";
import AdminCouriers from "../Pages/Admin/AdminCouriers";
import {Shifts} from "../Pages/Admin/Shifts";
import TotalShifts from "../Pages/Admin/TotalShifts";
import CourierShift from "../Pages/Admin/CourierShift";
import CouriersTotal from "../Pages/Admin/CouriersTotal";

const useRoutes = () => {
  return (
    <>
      <Route path="/" element={<Index />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/stuff" element={<Stuff />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/promocode" element={<Promocode />} />
      <Route path="/delivery-terms" element={<DeliveryTerms />} />
      <Route path="/promotions" element={<Promotions />} />
      <Route path="/novelties" element={<Novelties />} />
      <Route path="/novelties-vapes" element={<NoveltiesVapes />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/addresses" element={<Addresses />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order" element={<Order />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/order-created" element={<OrderCreated />} />
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/add_stuff"
        element={
          <ProtectedRoute>
            <AddStuff />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/edit_stuff"
        element={
          <ProtectedRoute>
            <EditStuff />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/edit_users"
        element={
          <ProtectedRoute>
            <EditUsers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/orders"
        element={
          <ProtectedRoute>
            <AdminOrders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/brands"
        element={
          <ProtectedRoute>
            <AdminBrands />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/snus-banner"
        element={
          <ProtectedRoute>
            <AdminSnusBanner />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/vape-banner"
        element={
          <ProtectedRoute>
            <AdminVapeBanner />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/models"
        element={
          <ProtectedRoute>
            <AdminModels />
          </ProtectedRoute>
        }
      />
      <Route
      path="/admin/order/:id"
      element={
        <ProtectedRoute>
          <AdminOrder />
        </ProtectedRoute>
      }
      />
      <Route
        path="/admin/couriers"
        element={
          <ProtectedRoute>
            <AdminCouriers />
          </ProtectedRoute>
        }
      />
    <Route
        path="/admin/shifts"
        element={
            <ProtectedRoute>
                <Shifts />
            </ProtectedRoute>
        }
    />
        <Route
        path="/admin/total-shifts"
        element={
            <ProtectedRoute>
                <TotalShifts />
            </ProtectedRoute>
        }
    />
        <Route
            path="/admin/total-shifts/:shiftId"
            element={
                <ProtectedRoute>
                    <CourierShift />
                </ProtectedRoute>
            }
        />
        <Route
            path="/admin/couriers-total"
            element={
                <ProtectedRoute>
                    <CouriersTotal />
                </ProtectedRoute>
            }
        />
    </>
  );
};

export default useRoutes;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import fetchData from "../../Lib/fetchData";
import { CartContext } from "../../Context/CartContext";
import StuffUpperBlock from "../../Components/Stuff/StuffUpperBlock";
import StuffDescription from "../../Components/Stuff/StuffDescription";
import { HistoryContext } from "../../Context/HistoryContext";
import "./stuff.css";
import Loader3Packs from "../Loaders/Loader3Packs";

function Stuff() {
  const { cart } = useContext(CartContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [stuff, setStuff] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const { path } = useContext(HistoryContext);

  const updateQty = (qty) => {
    setQuantity(qty);
  }

  useEffect(() => {
    const getDescription = async () => {
      const response = await fetchData(
        `/stuff?id=${searchParams.get("id")}`,
        "GET"
      );

      if (response.status === 200) {
        const stuff = await response.json();
        setStuff(stuff);
        setLoading(false);
      }
    };

    const stuffId = searchParams.get("id");
    const res = cart.find((item) => item._id === stuffId);
    if (res) {
      getDescription()
      setQuantity(res.quantity);
      setStuff(res);
    } else getDescription();
  }, []);

  const addToQuantity = useCallback(() => {
    setQuantity((prevState) => ++prevState);
  }, []);

  const removeFromQuantity = useCallback(() => {
    setQuantity((prevState) => --prevState);
  }, []);

  if (loading) {
    return <Loader3Packs />;
  }

  if (Object.keys(stuff).length !== 0)
    return (
      <Container fluid className="h-100 px-0">
        <StuffUpperBlock
          link={`${process.env.REACT_APP_AWS_URL}/images/${stuff.image}`}
          id={searchParams.get("id")}
        />
        <StuffDescription
          stuff={stuff}
          quantity={quantity}
          addToQuantity={addToQuantity}
          removeFromQuantity={removeFromQuantity}
          updateQty={updateQty}
        />
      </Container>
    );
}

export default Stuff;

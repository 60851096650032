import React, { useRef } from "react";
import fetchData from "../../Lib/fetchData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const usernameInput = useRef();
  const passwordInput = useRef();
  const navigate = useNavigate();
  const notify = (msg) => toast(msg);

  const login = async () => {
    const username = usernameInput.current.value;
    const password = passwordInput.current.value;
    if (username === "" || password === "") {
      return notify("Заполните поля");
    }
    const data = await fetchData("/admin/login", "POST", {
      username,
      password,
    });
    if (data.status !== 201) {
      return notify("Неправильное имя пользователя или пароль");
    }

    localStorage.setItem("adminToken", await data.text());
    navigate("/admin");
  };

  return (
    <>
      <div className="position-relative vh-100 vw-100">
        <div className="position-absolute top-50 start-50 translate-middle text-center w-25">
          <h2>Вход</h2>
          <input
            type="text"
            className="form-control w-75 m-auto"
            placeholder="Имя пользователя"
            ref={usernameInput}
          />
          <input
            type="password"
            className="form-control mt-2 w-75 m-auto"
            placeholder="Пароль"
            ref={passwordInput}
          />
          <button
            type="button"
            className="btn btn-primary mt-2 w-25"
            onClick={login}
          >
            Войти
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminLogin;

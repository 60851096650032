import React from "react";

const ModelPriority = ({ model, modelPriority, changeModelPriority }) => {
  return (
    <div className="mb-2">
      <span className="me-2">{model}</span>
      <input
        type="text"
        className="form-control mb-2 w-auto d-inline"
        placeholder={modelPriority}
        onChange={(e) => changeModelPriority(model, Number(e.target.value))}
      />
    </div>
  );
};

export default ModelPriority;

import React from "react";
import { Col, Row } from "react-bootstrap";

const Category = ({
  title,
  img,
  isFromShop,
  setActiveFilter,
  activeFilter,
  category,
  imgActive,
}) => {
  if (isFromShop) {
    return (
      <Col className="text-center col-6 col-sm-3 mt-2 col-category">
        <div
          className="category-block p-1 d-flex align-content-center justify-content-center"
          style={{ cursor: "pointer" }}
        >
          <div className="h-100 d-flex flex-row justify-content-center align-items-center">
            <img src={img} alt="" className="" width="85%" height="85%" />
          </div>
        </div>
        <div className="mt-2 category-name">{title}</div>
      </Col>
    );
  } else {
    return (
      <Col
        className="text-center col-6 col-sm-3 mt-2 col-category"
        onClick={() => setActiveFilter(category)}
      >
        <div
          className={`category-block p-1 d-flex align-content-center justify-content-center ${
            activeFilter === category ? "category-active" : ""
          }`}
          style={{ cursor: "pointer" }}
        >
          <div className="h-100 d-flex flex-row align-items-center justify-content-center">
            {activeFilter === category ? (
              <img
                src={imgActive}
                alt=""
                className=""
                width="85%"
                height="85%"
              />
            ) : (
              <img src={img} alt="" className="" width="85%" height="85%" />
            )}
          </div>
        </div>
        <div className="mt-2 category-name">{title}</div>
      </Col>
    );
  }
};

export default Category;

import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import { Container } from "react-bootstrap";
import FlexElement from "../../Components/UI/FlexElement";
import bagSvg from "../../images/icons/Bag.svg";
import addressesSvg from "../../images/icons/Addresses.svg";
import settingsSvg from "../../images/icons/Settings.svg";
import loyaltySvg from "../../images/icons/LoyaltyProgram.svg";
import { Link, useNavigate } from "react-router-dom";
import "./profile.css";
import fetchData from "../../Lib/fetchData";
import Loader3Packs from "../Loaders/Loader3Packs";

const Profile = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProfile = async () => {
      const usr = await fetchData("/profile", "GET").then((res) => res.json());
      setUser(usr);
      setLoading(false);
    };

    getProfile();
  }, []);

  if (loading) {
    return <Loader3Packs />;
  }

  return (
    <>
      <Header title="Личный кабинет" />
      {Object.keys(user).length !== 0 && (
        <Container fluid className="mt-5">
          <div className="profile-id">
            {user.username ? `@${user.username}` : ""}
          </div>
          <div className="mt-4">
            <div className="profile-username">ID: {user.userId}</div>
          </div>
          <div className="profile-links mt-4">
            <Link to="/orders">
              <FlexElement text="Заказы" svg={bagSvg} />
            </Link>
            <Link to="/addresses">
              <FlexElement text="Адреса" svg={addressesSvg} />
            </Link>
            <FlexElement text="Программа лояльности" svg={loyaltySvg} />
            <Link to="/settings">
              <FlexElement text="Настройки" svg={settingsSvg} />
            </Link>
          </div>
        </Container>
      )}
    </>
  );
};

export default Profile;

import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import useRoutes from "./useRoutes";
import ScrollToTop from "../Components/ScrollToTop";

const ReactRouter = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>{useRoutes()}</Routes>
    </BrowserRouter>
  );
};

export default ReactRouter;

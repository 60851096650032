import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-5">
      <h1 className="text-center mb-3">Админ панель</h1>
      <div className="d-flex flex-column">
        <Link to="/admin/orders" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/cart.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Заказы</h3>
        </Link>
        <Link to="/admin/edit_users" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/user.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Просмотр пользователей</h3>
        </Link>
        <Link to="/admin/edit_stuff" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/pencil.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Редактирование товаров</h3>
        </Link>
        <Link to="/admin/add_stuff" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/plus.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Добавление товара</h3>
        </Link>
        <Link to="/admin/brands" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/pointers.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Редактирование порядка брендов</h3>
        </Link>
        <Link to="/admin/models" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/pointers.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Редактирование порядка моделей</h3>
        </Link>
        <Link to="/admin/snus-banner" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/new.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Редактирование баннера новинок снюса</h3>
        </Link>
        <Link to="/admin/vape-banner" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/new.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Редактирование баннера новинок вейпа</h3>
        </Link>
        <Link to="/admin/couriers" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/user.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Просмотр курьеров</h3>
        </Link>
        <Link to="/admin/total-shifts" className="d-flex gap-4 border-1 border-top border-black border-bottom align-items-center py-3 px-5">
          <img src="/24hours.svg" alt="cart" width="48" height="48" />
          <h3 className="text-black">Смены курьеров</h3>
        </Link>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import LeftArrow from "../Components/UI/LeftArrow";
import SpecialHeader from "../Components/Common/SpecialHeader/SpecialHeader";
import NoveltiesIcon from "../images/imgs/NoveltiesIcon.png";
import StuffList from "../Components/Common/StuffList";
import fetchData from "../Lib/fetchData";
import { useNavigate } from "react-router-dom";
import Loader3Packs from "./Loaders/Loader3Packs";

const NoveltiesVapes = () => {
  const [noveltiesStuff, setNoveltiesStuff] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getNoveltiesStuff = async () => {
      const response = await fetchData(
        `/banner/64f03fbe3c2bb380fb2924d1`,
        "GET"
      );

      if (response.status === 200) {
        const stuff = await response.json();
        setNoveltiesStuff(stuff.participatedStuff);
        setLoading(false);
      }
    };

    getNoveltiesStuff();
  }, []);

  if (loading) {
    return <Loader3Packs />;
  }

  return (
    <div>
      <SpecialHeader
        title="Новинки подов"
        gradientColor="linear-gradient(180deg, #EB00FF 0%, #6100FF 100%)"
        svg={NoveltiesIcon}
      />
      <StuffList stuffs={noveltiesStuff} />
    </div>
  );
};

export default NoveltiesVapes;

import React from 'react';
import SpecialHeader from "../Components/Common/SpecialHeader/SpecialHeader";
import NoveltiesIcon from "../images/imgs/NoveltiesIcon.png";

const Promocode = () => {
  return (
    <div>
      <SpecialHeader
        title="Промокод"
        gradientColor="linear-gradient(180deg, #11998E 0%, #38EF7D 100%)"
        svg={NoveltiesIcon}
      />
      <div className="text-left mx-4 mt-5">
        <p>Получи бесплатную доставку при первом заказе!</p>
        <p>Условия работы промокода:</p>
        <p>Промокод <b>NGSFREE</b> является действительным только при <b>самом первом заказе</b> и с количеством единиц товара от <b>пяти штук</b>.</p>
      </div>
    </div>
  );
};

export default Promocode;
import React from "react";

const UserRow = ({
  id,
  name,
  surname,
  username,
  telephone,
  secret,
  registrationDate,
}) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{surname}</td>
      <td>{username}</td>
      <td>{telephone}</td>
      <td>{secret}</td>
      <td>{registrationDate}</td>
    </tr>
  );
};

export default UserRow;

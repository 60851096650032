import React from "react";
import loaderAnim from "../../animations/3packs.json";
import Lottie from "lottie-react";
import "./loader.css";

const Loader3Packs = () => {
  return (
    <div className="div-loader d-flex">
      <Lottie
        animationData={loaderAnim}
        loop={true}
        className="w-50 text-center m-auto"
      />
    </div>
  );
};

export default Loader3Packs;

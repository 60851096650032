import React, { useCallback, useRef, useState } from "react";
import { Button, Container, InputGroup } from "react-bootstrap";
import fetchData from "../../Lib/fetchData";
import { ToastContainer, toast } from "react-toastify";

const AddStuff = () => {
  const [category, setCategory] = useState("category");
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState(null);
  const [priceFromOne, setPriceFromOne] = useState(0);
  const [priceFromFive, setPriceFromFive] = useState(0);
  const [priceFromTen, setPriceFromTen] = useState(0);
  const [flavor, setFlavor] = useState(null);
  const [nicotineContent, setNicotineContent] = useState(null);
  const [numberOfUses, setNumberOfUses] = useState(null);
  const [model, setModel] = useState(null);
  const [color, setColor] = useState(null);
  const [isTobacco, setIsTobacco] = useState(true);
  const [type, setType] = useState(null);
  const [configuration, setConfiguration] = useState(null);
  const [liquidCapacity, setLiquidCapacity] = useState(null);
  const [batteryCapacity, setBatteryCapacity] = useState(null);
  const [maxPower, setMaxPower] = useState(null);
  const [coilType, setCoilType] = useState(null);
  const [resistance, setResistance] = useState(null);
  const [compatibility, setCompatibility] = useState(null);
  const [file, setFile] = useState();
  const [cartridgeCapacity, setCartridgeCapacity] = useState(null);

  const notify = useCallback((msg) => toast(msg), []);
  const notifyError = useCallback((msg) => toast.error(msg), []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const addStuff = async () => {
    if (
      title === "" ||
      brand === "" ||
      category === "" ||
      priceFromOne === 0 ||
      priceFromFive === 0 ||
      priceFromTen === 0
    )
      return notifyError("Заполните все обязательные поля");

    if(file.size > (200 * 1024)) return notifyError('Файл должен быть меньше 200КБ')
    let res;
    if (category === "snus") {
      res = await fetchData(
        "/admin/add_stuff",
        "POST",
        {
          title,
          category,
          brand,
          description,
          priceFromOne,
          priceFromFive,
          priceFromTen,
          flavor,
          nicotineContent,
          numberOfUses,
          isTobacco,
          type,
        },
        true
      );
    } else if (category === "pods") {
      res = await fetchData(
        "/admin/add_stuff",
        "POST",
        {
          title,
          category,
          brand,
          model,
          description,
          priceFromOne,
          priceFromFive,
          priceFromTen,
          flavor,
          nicotineContent,
          numberOfUses,
          type,
        },
        true
      );
    } else if (category === "liquids") {
      res = await fetchData(
        "/admin/add_stuff",
        "POST",
        {
          title,
          category,
          brand,
          model,
          description,
          priceFromOne,
          priceFromFive,
          priceFromTen,
          flavor,
          nicotineContent,
          liquidCapacity,
        },
        true
      );
    } else if (category === "devices") {
      if (numberOfUses !== 0) {
        res = await fetchData(
          "/admin/add_stuff",
          "POST",
          {
            title,
            category,
            brand,
            model,
            description,
            priceFromOne,
            priceFromFive,
            priceFromTen,
            color,
            batteryCapacity,
            type,
            configuration,
            numberOfUses,
            maxPower,
            coilType,
            resistance,
            compatibility,
            cartridgeCapacity,
            flavor,
            nicotineContent
          },
          true
        );
      } else {
        res = await fetchData(
          "/admin/add_stuff",
          "POST",
          {
            title,
            category,
            brand,
            model,
            description,
            priceFromOne,
            priceFromFive,
            priceFromTen,
            color,
            batteryCapacity,
            type,
            configuration,
          },
          true
        );
      }
    }

    if (res.status === 201) {
      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("entity", "stuff");
      const baseUrl = process.env.REACT_APP_SERVER_URL;
      const stuffId = (await res.json())._id;

      const data = await fetch(baseUrl + "/media/" + stuffId, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adminToken"),
        },
      });

      if (data.status === 201) {
        notify("Товар был успешно добавлен");
      } else {
        notifyError("Максимальный размер изображения 200КБ");
      }
    }
  };

  return (
    <>
      <Container fluid>
        <Container className="w-50 text-center pt-5">
          <h3>Добавление товара</h3>
          {file && (
            <img src={URL.createObjectURL(file)} width={128} height={128} />
          )}
          <input
            type="file"
            onChange={handleFileChange}
            className="form-control mb-2"
          />
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Название"
            onChange={(e) => setTitle(e.target.value.trim())}
          />
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Бренд"
            onChange={(e) => setBrand(e.target.value.trim())}
          />
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Описание (необязательно)"
            onChange={(e) => setDescription(e.target.value.trim())}
          />
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Цена от 1 ед."
            onChange={(e) => setPriceFromOne(Number(e.target.value))}
          />
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Цена от 5 ед."
            onChange={(e) => setPriceFromFive(Number(e.target.value))}
          />
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Цена от 10 ед."
            onChange={(e) => setPriceFromTen(Number(e.target.value))}
          />
          <select
            className="form-select mb-2"
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="category" selected>
              Категория
            </option>
            <option value="snus">Снюс</option>
            <option value="pods">Поды</option>
            <option value="liquids">Жижа</option>
            <option value="devices">Устройства</option>
          </select>
          {category === "snus" && (
            <>
              <select
                className="form-select mb-2"
                onChange={(e) => {
                  setIsTobacco(e.target.value === "tobacco");
                  setType(
                    e.target.value === "tobacco"
                      ? "Табачный снюс"
                      : "Безтабачный снюс"
                  );
                }}
              >
                <option value="default" selected>
                  Табачный снюс
                </option>
                <option value="tobacco">Да</option>
                <option value="non-tobacco">Нет</option>
              </select>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Вкус"
                onChange={(e) => setFlavor(e.target.value.trim())}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Содержание никотина (мг/г)"
                onChange={(e) => setNicotineContent(Number(e.target.value))}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Кол-во порций"
                onChange={(e) => setNumberOfUses(Number(e.target.value))}
              />
            </>
          )}
          {category === "pods" && (
            <>
              <select
                className="form-select mb-2"
                onChange={(e) =>
                  setType(
                    e.target.value === "disposable"
                      ? "одноразовый под"
                      : "под с возможностью зарядки"
                  )
                }
              >
                <option value="default" selected>
                  Тип пода
                </option>
                <option value="disposable">Одноразовый под</option>
                <option value="non-disposable">
                  Под с возможностью зарядки
                </option>
              </select>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Вкус"
                onChange={(e) => setFlavor(e.target.value.trim())}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Линейка"
                onChange={(e) => setModel(e.target.value.trim())}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Содержание солевого никотина (%)"
                onChange={(e) => setNicotineContent(Number(e.target.value))}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Кол-во затяжек"
                onChange={(e) => setNumberOfUses(Number(e.target.value))}
              />
            </>
          )}
          {category === "liquids" && (
            <>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Вкус"
                onChange={(e) => setFlavor(e.target.value.trim())}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Линейка"
                onChange={(e) => setModel(e.target.value.trim())}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Содержание никотина (%)"
                onChange={(e) => setNicotineContent(Number(e.target.value))}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Объем (мл)"
                onChange={(e) => setLiquidCapacity(Number(e.target.value))}
              />
            </>
          )}
          {category === "devices" && (
            <>
              <select
                className="form-select mb-2"
                onChange={(e) =>
                  setType(
                    e.target.value
                  )
                }
              >
                <option value="default" selected>
                  Тип устройства
                </option>
                <option value="Pod Kit">Устройства</option>
                <option value="картридж/испаритель">
                  Испарители
                </option>
              </select>

              {type === "Pod Kit" && (
                <>
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Модель (необязательно)"
                    onChange={(e) => setModel(e.target.value.trim())}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Цвет"
                    onChange={(e) => setColor(e.target.value.trim())}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Емкость аккумулятора мАч"
                    onChange={(e) => setBatteryCapacity(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Объем бака мл "
                    onChange={(e) => setCartridgeCapacity(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Комплектация "
                    onChange={(e) => setConfiguration(e.target.value.trim())}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Максимальная мощность (Вт)"
                    onChange={(e) => setMaxPower(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Тип испарителя"
                    onChange={(e) => setCoilType(e.target.value.trim())}
                  />
                </>)}
              {type === "картридж/испаритель" && (
                <>
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Модель (необязательно)"
                    onChange={(e) => setModel(e.target.value.trim())}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Вкус (необязательно)"
                    onChange={(e) => setFlavor(e.target.value.trim())}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Количество затяжек (необязательно)"
                    onChange={(e) => setNumberOfUses(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Содержание солевого никотина (необязательно)"
                    onChange={(e) => setNicotineContent(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Комплектация"
                    onChange={(e) => setConfiguration(e.target.value.trim())}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Сопротивление (Ом) (необязательно)"
                    onChange={(e) => setResistance(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Совместимость (необязательно)"
                    onChange={(e) => setCompatibility(e.target.value.trim())}
                  />
                </>
              )}
            </>
          )}
          <Button
            variant="primary"
            disabled={category === "category"}
            onClick={() => addStuff()}
          >
            Добавить
          </Button>
        </Container>
      </Container>
      <ToastContainer />
    </>
  );
};

export default AddStuff;

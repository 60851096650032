import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import deliveryBanner from "../../images/banner-delivery.jpg";
import promotionsBanner from "../../images/banner-promotions.jpg";
import promoBanner from "../../images/banner-promo.jpg";
import { Link } from "react-router-dom";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import fetchData from "../../Lib/fetchData";

const SpecOffers = () => {
  const [noveltiesImgId, setNoveltiesImgId] = useState("");
  const [vapeImgId, setVapeImgId] = useState("");

  useEffect(() => {
    const getId = async () => {
      const data = await fetchData("/banner/64a591f8b7ecdcf76c82e8a4", "GET");
      const dataVapes = await fetchData("/banner/64f03fbe3c2bb380fb2924d1", "GET");
      const id = (await data.json()).image;
      setVapeImgId((await dataVapes.json()).image)
      setNoveltiesImgId(id);
    };

    getId();
  }, []);

  return (
    <div className="mt-4">
      <p className="px-3 shop-header">Специальные предложения</p>
      <Swiper
        modules={[Pagination]}
        className="spec-offers-swiper"
        spaceBetween={8}
        slidesPerView={"auto"}
        pagination={{ clickable: true }}
      >
        <SwiperSlide
          className="spec-offer-div"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_AWS_URL}/banners/${noveltiesImgId})`, backgroundSize: "280px 120px"
          }}
        >
          <Link to="/novelties">
            <div className="w-100 h-100"></div>
          </Link>
        </SwiperSlide>
        <SwiperSlide
          className="spec-offer-div"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_AWS_URL}/banners/${vapeImgId})`, backgroundSize: "280px 120px"
          }}
        >
          <Link to="/novelties-vapes">
            <div className="w-100 h-100"></div>
          </Link>
        </SwiperSlide>
        <SwiperSlide
          className="spec-offer-div"
          style={{ backgroundImage: `url(${promoBanner})`, backgroundSize: "280px 120px" }}
        >
          <Link to="/promocode">
            <div className="w-100 h-100"></div>
          </Link>
        </SwiperSlide>
        <SwiperSlide
          className="spec-offer-div"
          style={{ backgroundImage: `url(${promotionsBanner})`, backgroundSize: "280px 120px" }}
        >
          <Link to="/promotions">
            <div className="w-100 h-100"></div>
          </Link>
        </SwiperSlide>
        <SwiperSlide
          className="spec-offer-div"
          style={{ backgroundImage: `url(${deliveryBanner})`, backgroundSize: "280px 120px" }}
        >
          <Link to="/delivery-terms">
            <div className="w-100 h-100"></div>
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SpecOffers;

const getTheme = (colorScheme) => {
  let root = document.documentElement;

  root.style.setProperty("--smoke-color", "rgba(255, 255, 255, 0.87)");
  root.style.setProperty("--main-button-color", "#FF9400");
  root.style.setProperty("--grey-color-reg", "rgba(255, 255, 255, 0.6)");
  if (colorScheme === "light") {
    root.style.setProperty("--background-color", "#FFFFFF");
    root.style.setProperty("--main-text-color", "#FF8A00");
    root.style.setProperty("--grey-color", "rgba(0, 0, 0, 0.38)");
    root.style.setProperty("--text-color", "rgba(0, 0, 0, 0.87)");
    root.style.setProperty("--grey-description", "rgb(102,102,102)");
    root.style.setProperty("--left-arrow-color", "rgba(0, 0, 0, 0.74)");
    root.style.setProperty("--left-arrow-filter", "none");
    root.style.setProperty("--btn-cart-quantity", "#FFF3E0");
    root.style.setProperty(
      "--delivery-outline",
      "1px solid rgba(0, 0, 0, 0.12)"
    );
    root.style.setProperty(
      "--delivery-outline",
      "1px solid rgba(0, 0, 0, 0.12)"
    );
    root.style.setProperty("--input-outline", "#e0e0e0");
    root.style.setProperty("--grey-color-del", "rgba(0, 0, 0, 0.6)");
    root.style.setProperty("--stuff-card-bg", "#FFF");
    root.style.setProperty("--flex-list-border", "rgba(33, 33, 33, 0.08)");
    root.style.setProperty(
      "--stuff-card-shadow",
      "0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)"
    );
    root.style.setProperty("--search-bg", "#FFF3E0");
    root.style.setProperty("--cart-fill-color", "black");
    root.style.setProperty("--filter-active", "#FFDFB1");
    root.style.setProperty("--bg-order-info", "#ABABAB26");
    root.style.setProperty('--bg-search', 'none');
  } else {
    root.style.setProperty("--background-color", "#121212");
    root.style.setProperty("--main-text-color", "#FF8A00");
    root.style.setProperty("--text-color", "rgba(255, 255, 255, 0.87)");
    root.style.setProperty("--grey-color", "rgba(255, 255, 255, 0.38)");
    root.style.setProperty("--grey-description", "rgb(160,160,160)");
    root.style.setProperty(
      "--left-arrow-filter",
      "invert(70%) sepia(0%) saturate(14%) hue-rotate(158deg) brightness(92%) contrast(88%)"
    );
    root.style.setProperty("--btn-cart-quantity", "rgba(255, 255, 255, 0.38)");
    root.style.setProperty(
      "--delivery-outline",
      "1px solid rgba(255, 255, 255, 0.12)"
    );
    root.style.setProperty("--input-outline", "#2f2f2f");
    root.style.setProperty("--grey-color-del", "rgba(255, 255, 255, 0.6)");
    root.style.setProperty("--stuff-card-bg", "#2E2D2D");
    root.style.setProperty("--flex-list-border", "rgba(255, 255, 255, 0.12)");
    root.style.setProperty(
      "--stuff-card-shadow",
      "0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)"
    );
    root.style.setProperty("--search-bg", "rgba(255, 255, 255, 0.12)");
    root.style.setProperty("--cart-fill-color", "white");
    root.style.setProperty("--filter-active", "rgba(255, 255, 255, 0.38)");
    root.style.setProperty("--bg-order-info", "none");
    root.style.setProperty('--bg-search', '#2E2D2D');
  }
};

export default getTheme;

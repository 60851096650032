/* eslint-disable */
import React, { useMemo } from "react";

const Checkout = ({ cart, deliveryType, isInsideMKAD, isFreeDelivery, isPromoActivated, paymentType, promoId }) => {
  const originalPrice = useMemo(() => {
    let total = 0;
    cart.forEach((v) => (total += v.priceFromOne * v.quantity));
    return total;
  }, [cart]);

  const totalQuantity = useMemo(() => {
    let total = 0;
    cart.forEach((v) => (total += v.quantity));
    return total;
  }, [cart]);

  const discount = useMemo(() => {
    let total = 0;

    cart.forEach((v) => {
      if (v.discount) {
        total += v.discount * v.quantity;
      } else {
        if (totalQuantity >= 5 && totalQuantity < 10)
          total += v.priceFromOne * v.quantity - v.priceFromFive * v.quantity;
        else if (totalQuantity >= 10)
          total += v.priceFromOne * v.quantity - v.priceFromTen * v.quantity;
      }
    });

    return total;
  }, [cart]);

  const deliveryPrice = useMemo(() => {
    if (deliveryType === "pick_up") return 0;
    if (deliveryType === "delivery") {
      return isInsideMKAD ? 350 : 450;
    } else if (deliveryType === "post") {
      return 450;
    }
  }, [deliveryType, isInsideMKAD, totalQuantity]);

  return (
      <div className="checkout">
        <div className="checkout-elem">
          <span>Сумма заказа:</span>
          <span>{ paymentType !== 'usdt' ? `${originalPrice} ₽` : `${fx(originalPrice).from("RUB").to("USD").toFixed(2)} $`}</span>
        </div>
        <div className="checkout-elem">
          <span>Доставка:</span>
          <span className={`${totalQuantity >= 40 || (isFreeDelivery && totalQuantity >= 5 && !isPromoActivated && promoId === 0) ? 'discount' : ''}`}>
            { paymentType !== 'usdt' ? `${deliveryPrice} ₽` : `${fx(deliveryPrice).from("RUB").to("USD").toFixed(2)} $`}</span>
        </div>
        {discount !== 0 && (
          <>
            <div className="checkout-elem">
              <span>Скидка:</span>
              <span>
                { paymentType !== 'usdt' ? `-${discount} ₽` : `-${fx(discount).from("RUB").to("USD").toFixed(2)} $`}
              </span>
            </div>
          </>
        )}
        {
          (promoId === 1 && !isPromoActivated && isFreeDelivery) && (
            <div className="checkout-elem checkout-elem-total">
              <span>Промокод:</span>
              <span>
                {`-${Math.floor((originalPrice - discount + ((totalQuantity >= 40) ? 0 : deliveryPrice)) / 10)} ₽` }
          </span>
            </div>
          )
        }
        {
          promoId !== 1 ? (
            <div className="checkout-elem checkout-elem-total">
              <span>Итого:</span>
              <span>
                { paymentType !== 'usdt' ? `${originalPrice - discount + ((totalQuantity >= 40 || (isFreeDelivery && totalQuantity >= 5 && !isPromoActivated)) ? 0 : deliveryPrice)} ₽` : `${fx(originalPrice - discount + ((totalQuantity >= 40 || (isFreeDelivery && totalQuantity >= 5 && !isPromoActivated)) ? 0 : deliveryPrice) + 2).from("RUB").to("USD").toFixed(2)} $` }
          </span>
            </div>
          ) : (
            <div className="checkout-elem checkout-elem-total">
              <span>Итого:</span>
              <span>
                { paymentType !== 'usdt' ? `${originalPrice - discount + ((totalQuantity >= 40) ? 0 : deliveryPrice) - (!isPromoActivated && isFreeDelivery ? Math.floor((originalPrice - discount + ((totalQuantity >= 40) ? 0 : deliveryPrice)) / 10) : 0)} ₽` : `${fx(originalPrice - discount + ((totalQuantity >= 40) ? 0 : deliveryPrice) - ((totalQuantity >= 40) ? 0 : deliveryPrice) - (!isPromoActivated && isFreeDelivery ? Math.floor((originalPrice - discount + ((totalQuantity >= 40) ? 0 : deliveryPrice)) / 10) : 0) + 2).from("RUB").to("USD").toFixed(2)} $` }
          </span>
            </div>
          )
        }
      </div>
  );
};

export default Checkout;

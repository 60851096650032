import React from "react";
import chatShopSvg from "../../images/icons/ShopChat.svg";

const ShopChatSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M19 4H17V13H4V15C4 15.55 4.45 16 5 16H16L20 20V5C20 4.45 19.55 4 19 4ZM15 10V1C15 0.45 14.55 0 14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10Z"
        fill={
          window.location.pathname === "/stuff"
            ? "black"
            : "var(--cart-fill-color)"
        }
        fillOpacity={window.location.pathname === "/stuff" ? "0.74" : "0.6"}
      />
    </svg>
  );
};

export default ShopChatSvg;

import React from "react";
import CartSvg from "../UI/CartSvg/CartSvg";
import { Link } from "react-router-dom";
import ProfileShopSvg from "../UI/ProfileShopSvg";
import ShopChatSvg from "../UI/ShopChatSvg";

const ShopHeader = () => {
  return (
    <div className="w-100 d-flex justify-content-between py-3 align-content-center">
      <div
        onClick={() => {
          window.Telegram.WebApp.openTelegramLink("https://t.me/snusngs_new");
        }}
      >
        <ShopChatSvg />
      </div>
      <Link to="/profile">
        <ProfileShopSvg />
      </Link>
      <Link to="/cart">
        <CartSvg />
      </Link>
    </div>
  );
};

export default ShopHeader;

import React, { useState } from "react";
import leavesIcon from "../../images/imgs/Leaves.png";
import leavesIconActive from "../../images/imgs/Leaves-Active.png";
import podsIcon from "../../images/imgs/Pods.png";
import podsIconActive from "../../images/imgs/Pods-Active.png";
import liquidIcon from "../../images/imgs/Liquid.png";
import liquidIconActive from "../../images/imgs/Liquid-Active.png";
import devicesIcon from "../../images/imgs/Devices.png";
import devicesIconActive from "../../images/imgs/Devices-Active.png";
import Category from "./Category";
import { Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const CategoriesList = ({ isFromShop, activeCategory, setActCategory }) => {
  const [active, setActive] = useState(activeCategory);

  const setActiveCategory = (category) => {
    setActive(category);
    setActCategory(category);
  };

  return (
    <div className="categories">
      {isFromShop && <p className="shop-header mb-2">Категории</p>}
      <Row className="px-1">
        {isFromShop ? (
          <>
            <Link to="/categories?category=snus" className="category-link">
              <Category title="Снюс" img={leavesIcon} isFromShop={true} />
            </Link>
            <Link to="/categories?category=pods" className="category-link">
              <Category title="Поды" img={podsIcon} isFromShop={true} />
            </Link>
            <Link to="/categories?category=liquids" className="category-link">
              <Category title="Жидкости" img={liquidIcon} isFromShop={true} />
            </Link>
            <Link to="/categories?category=devices" className="category-link">
              <Category
                title="Устройства"
                img={devicesIcon}
                isFromShop={true}
              />
            </Link>
          </>
        ) : (
          <>
            <Category
              title="Снюс"
              img={leavesIcon}
              imgActive={leavesIconActive}
              isFromShop={isFromShop}
              setActiveFilter={setActiveCategory}
              activeFilter={active}
              category="snus"
            />
            <Category
              title="Поды"
              img={podsIcon}
              imgActive={podsIconActive}
              isFromShop={isFromShop}
              setActiveFilter={setActiveCategory}
              activeFilter={active}
              category="pods"
            />
            <Category
              title="Жидкости"
              img={liquidIcon}
              imgActive={liquidIconActive}
              isFromShop={isFromShop}
              setActiveFilter={setActiveCategory}
              activeFilter={active}
              category="liquids"
            />
            <Category
              title="Устройства"
              img={devicesIcon}
              imgActive={devicesIconActive}
              isFromShop={isFromShop}
              setActiveFilter={setActiveCategory}
              activeFilter={active}
              category="devices"
            />
          </>
        )}
      </Row>
    </div>
  );
};

export default CategoriesList;

import React, { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import fetchData from "../../Lib/fetchData";
import { Col, Row } from "react-bootstrap";
import { HistoryContext } from "../../Context/HistoryContext";
import {CartContext} from "../../Context/CartContext";

const Search = () => {
  const { dispatch } = useContext(CartContext);
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const { setHistoryPath } = useContext(HistoryContext);
  const [isFocus, setIsFocus] = useState(false);
  const [activeButtons, setActiveButtons] = useState({});

  useEffect(() => {
    const getOptions = async () => {
      const stuff = await fetchData("/stuff/search", "GET").then((res) =>
        res.json()
      );


      setOptions(
        stuff.filter(v => !v.notAvailable).map((v) => ({
          value: v._id,
          label: (
            <Row className="search-block px-0">
              <Col className="link-img-div text-center position-relative">
                <img
                  src={`${process.env.REACT_APP_AWS_URL}/images/${v.image}`}
                  alt="Svg icon"
                  className="link-img position-absolute top-50 start-50 translate-middle ms-1 rounded-4"
                  width="60%"
                />
              </Col>
              <Col className="ms-0 px-0 col-9">
                <div className="d-flex justify-content-between">
                  <div className="search-elem">
                  <span className="text-search">{v.title}</span>
                  {v.description && (
                    <span className="desc-search">{v.description}</span>
                  )}
                  </div>
                  <button
                    className={`btn stuff-button-search ${activeButtons[v._id] ? 'search-active' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch({ payload: { ...v, quantity: 1 }, type: "add" });
                      setActiveButtons(prev => {
                            const newState = { ...prev, [v._id]: !prev[v._id] };
                            console.log(`Button ${v._id} clicked. Active state: ${newState[v._id]}`);
                            return newState;
                        });

                    }}
                  >
                    +
                  </button>
                </div>
              </Col>
            </Row>
          ),
          title: v.title,
        }))
      );
    };

    getOptions();
  }, []);

  return (
    <div className="mt-3 w-100 position-relative">
      <div className="position-relative">
        <svg
          className="position-absolute ms-3 loupe-img"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{zIndex: '1'}}
        >
          <path
            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
            fill={
              isFocus
                ? "#FF9400"
                : document.documentElement.style.getPropertyValue(
                    "--grey-color"
                  )
            }
            fillOpacity={isFocus ? "1" : "0.8"}
          />
        </svg>
        <Select
          showSearch
          placeholder="Поиск"
          optionFilterProp="children"
          onFocus={() => setIsFocus(true)}
          notFoundContent={<h3 className="py-3 text-center">Не найдено</h3>}
          onBlur={() => setIsFocus(false)}
          filterOption={(input, option) =>
            (option?.title ?? "").toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.title ?? "")
              .toLowerCase()
              .localeCompare((optionB?.title ?? "").toLowerCase())
          }
          onChange={(v) => {
            setHistoryPath("/shop");
            localStorage.setItem("path", '/shop');
            navigate("/stuff?id=" + v);
          }}
          options={options}
          className="search-input"
        />
      </div>
    </div>
  );
};

export default Search;

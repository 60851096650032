import React from "react";

const MainButton = ({ text, width, color, bgColor, margin }) => {
  return (
    <button
      className="main-button"
      style={{
        width,
        color,
        backgroundColor: bgColor,
        margin,
      }}
    >
      {text}
    </button>
  );
};

export default MainButton;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import fetchData from "../../Lib/fetchData";
import StuffList from "../Common/StuffList";

const Featured = () => {
  const [featuredStuff, setFeaturedStuff] = useState([]);

  useEffect(() => {
    const getFeaturedStuff = async () => {
      const response = await fetchData(`/stuff/featured`, "GET");

      if (response.status === 200) {
        const stuff = await response.json();
        setFeaturedStuff(stuff);
      }
    };

    getFeaturedStuff();
  }, []);
  return (
    <div className="mt-4 pb-3">
      <p className="px-3 shop-header mb-1">Избранное</p>
      <Row>{<StuffList stuffs={featuredStuff} />}</Row>
    </div>
  );
};

export default Featured;

import * as React from "react";
import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import fetchData from "../../Lib/fetchData";




function OrderDetails({ order }) {
    console.log(order)
    if (order.deliveryType === "delivery") {
        return (
            <tr className="border-1">
                <td className="text-start p-4"
                    style={{whiteSpace: "nowrap"}}>{new Date(order.createdAt || 0).toLocaleString()} {order.username} ({order._id.slice(
                    order.length - 6
                )})
                    <p>----------------</p>
                    <p>{order.address.address}</p>
                    <br/>
                    <p>
                        {order.orderedStuff.map((v) => (
                            <span>
                {`${v.title} ${
                    v.description || ""
                } - ${v.quantity} шт.`}
                                <br/>
              </span>
                        ))}
                    </p>
                    <br/>
                    <p>{order.phoneNumber}</p>
                    <p>Сумма {order.price.totalPrice}</p>
                    <p>----------------</p>
                    <p>{order.comment}</p>
                </td>
            </tr>
        );
    } else if (order.deliveryType === "pick_up") {
        return (
            <tr className="border-1">
                <td className="text-start p-4"
                    style={{whiteSpace: "nowrap"}}>{new Date(order.createdAt || 0).toLocaleString()} {order.username} ({order._id.slice(
                    order.length - 6
                )})
                    <p>----------------</p>
                    <p>Самовывоз {order.pickUpLocation || ''}</p>
                    <br/>
                    <p>
                        {order.orderedStuff.map((v) => (
                            <span>
                {`${v.title} ${
                    v.description || ""
                } - ${v.quantity} шт.`}
                                <br/>
              </span>
                        ))}
                    </p>
                    <br/>
                    <p>{order.phoneNumber}</p>
                    <p>Сумма {order.price.totalPrice}</p>
                    <p>----------------</p>
                    <p>{order.pickUpTime}</p>
                    <p>{order.comment}</p>
                </td>
            </tr>
        );
    } else if (order.deliveryType === "post") {
        return (
            <tr className="text-left border-1 ">
                <td className="text-start p-4"
                    style={{whiteSpace: "nowrap"}}>{new Date(order.createdAt || 0).toLocaleString()} {order.username} ({order._id.slice(
                    order.length - 6
                )})
                    <p>----------------</p>
                    <p>Отправить почтой</p>
                    <p>Первый класс</p>
                    <p>
                        {order.orderedStuff.map((v) => (
                            <span>
                {`${v.title} ${
                    v.description || ""
                } - ${v.quantity} шт.`}
                                <br/>
              </span>
                        ))}
                    </p>
                    <br/>
                    <p>
                        Адрес: <br/>
                        {order.address.address}
                    </p>
                    <p>
                        Получатель: <br/>
                        {order.address?.fullName}
                    </p>
                    <p>
                        Контактный номер: <br/>
                        {order.phoneNumber}
                    </p>
                    <p>Индекс: {order.address?.postcode}</p>
                    <p>Сумма {order.price.totalPrice}</p>
                    <p>----------------</p>
                </td>
            </tr>
        );
    }
}

function StatCard({title, value, description}) {
        return (
            <div className="stat-card">
                <div className="title">{title}</div>
                <div className="value">{value}</div>
                <div className="description">{description}</div>
        </div>
    );
}

function App() {
    const params = useParams();
    const [orders, setOrders] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        const getData = async () => {
            const res = await fetchData(`/shifts/${params.shiftId}`, 'GET', undefined, true);
            const data = await res.json();
            setOrders(data.completedOrders)
            setData(data);
        }

        getData();
    }, []);

    const totalOrders = orders.length;

    return (
        <>
            <header>
                <div className="header-content">Курьер 1 ({new Date(data.endDate || 0).toLocaleDateString()})</div>
            </header>
            <nav className="stats-nav justify-content-around">
                <div className="stats-item">Завершенные заказы</div>
                <div className="stats-item">Продажи</div>
                <div className="stats-item">Отчет</div>
            </nav>
            <hr className="separator" />
            <main className="main-content">
                <section className="orders-section">
                    <table>
                        <tbody>
                        {orders.map((order, index) => (
                            <OrderDetails key={index} order={order} />
                        ))}
                        </tbody>
                    </table>
                </section>
                <section className="orders-section">
                    {orders.map((order, index) => (
                        order.orderedStuff.map((stuff, index) =>
                            <StatCard
                                key={index}
                                title={stuff.title+ " " + (stuff.description || '')}
                                value=""
                                description={stuff.quantity}
                            />)
                    ))}
                </section>
                <aside className="stats-section">
                    <StatCard
                        title="Количество заказов"
                        value=""
                        description={totalOrders}
                    />
                    <StatCard
                        title="Зарплата"
                        value=""
                        description={`${data.salary} руб`}
                    />
                    <StatCard
                        title="Сумма продаж"
                        value=""
                        description={`${data.totalGross} руб`}
                    />
                    <StatCard
                        title="Расходы"
                        value=""
                        description={`${data.expenses} руб`}
                    />
                    <StatCard
                        title="Итого"
                        value=""
                        description={`${data.totalNet} руб`}
                    />
                    <div className="report-download">Скачать отчет (.xlsx)</div>
                </aside>
            </main>
            <style jsx>{`
        body {
          font-family: 'Monstserrat', sans-serif;
        }
        .header-content {
          background-color: #b5b5b5;
          padding: 36px 60px;
          text-align: center;
          font-weight: 600;
          font-size: 32px;
        }
        .stats-nav {
          display: flex;
          justify-content: center;
          gap: 20px;
          margin: 40px 0;
        }
        .stats-item {
          font-size: 20px;
          font-weight: 600;
        }
        .separator {
          border: 1px solid rgba(0, 0, 0, 0.9);
        }
        .main-content {
          display: flex;
          justify-content: center;
          gap: 20px;
          max-width: 1405px;
          margin: 0 auto;
        }
        .orders-section,
        .stats-section {
          flex: 1;
        }
        .order-details p {
          border: 1px solid rgba(0, 0, 0, 0.9);
          padding: 20px;
        }
        .stat-card {
          display: flex;
            justify-content: space-between;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 20px;
          margin-bottom: 10px;
        }
        .stat-card .title,
        .stat-card .value,
        .stat-card .description {
          margin: 5px 0;
        }
        .report-download {
          background-color: #d9d9d9;
          color: #ff9400;
          text-align: center;
          padding: 10px 20px;
        }
        @media (max-width: 991px) {
          .header-content {
            padding: 0 20px;
          }
          .stats-nav {
            flex-wrap: wrap;
          }
          .main-content {
            flex-direction: column;
            max-width: 100%;
          }
          .order-details p {
            margin-top: 40px;
          }
          .stat-card {
            flex-wrap: wrap;
          }
          .stat-card .value {
            white-space: initial;
          }
        }
      `}</style>
        </>
    );
}

export default App;
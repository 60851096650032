import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import FlexElement from "../../Components/UI/FlexElement";
import profileSvg from "../../images/icons/Profile.svg";
import phoneSvg from "../../images/icons/Phone.svg";
import { Container } from "react-bootstrap";
import fetchData from "../../Lib/fetchData";
import { useNavigate } from "react-router-dom";
import Loader3Packs from "../Loaders/Loader3Packs";

const Settings = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getProfile = async () => {
      const usr = await fetchData("/profile", "GET").then((res) => res.json());
      setUser(usr);
      setLoading(false);
    };

    getProfile();
  }, []);

  const deleteAccount = async () => {
    await fetchData("/profile/delete", "PATCH");

    window.Telegram.WebApp.close();
  };

  if (loading) {
    return <Loader3Packs />;
  }

  return (
    <>
      <Header title="Настройки" />
      {Object.keys(user).length !== 0 && (
        <>
          <Container fluid className="mt-3">
            <div className="settings-list">
              <FlexElement text={`${user.userId}`} svg={phoneSvg} />
              <FlexElement text={`@${user.username}`} svg={profileSvg} />
            </div>
          </Container>
          <p
            className="position-absolute bottom-0 start-50 translate-middle-x delete-account"
            onClick={() => deleteAccount()}
          >
            Удалить аккаунт
          </p>
        </>
      )}
    </>
  );
};

export default Settings;

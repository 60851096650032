import * as React from "react";

const CourierInfo = ({ courierName }) => (
    <div className="courier-info">{courierName}</div>
);

const OrderInfo = ({ orderCount }) => (
    <div className="info-wrapper">
        <div className="info-title">Количество заказов</div>
        <div className="info-content">{orderCount}</div>
    </div>
);

const SalaryInfo = ({ salary }) => (
    <div className="info-wrapper">
        <div className="info-title">Зарплата</div>
        <div className="info-content">{salary} руб</div>
    </div>
);

const SalesInfo = ({ salesAmount }) => (
    <div className="info-wrapper">
        <div className="info-title">Сумма продаж</div>
        <div className="info-content">{salesAmount} руб</div>
    </div>
);

const ExpenseInfo = ({ expenseAmount }) => (
    <div className="info-wrapper">
        <div className="info-title">Расходы</div>
        <div className="info-content">{expenseAmount} руб</div>
    </div>
);

const TotalInfo = ({ totalAmount }) => (
    <div className="info-wrapper">
        <div className="total-title">Итого</div>
        <div className="total-content">{totalAmount} руб</div>
    </div>
);

function MyComponent() {
    const couriers = ["Курьер 1", "Курьер 2", "Курьер 3", "Курьер 4", "Курьер 5", "Курьер 6", "Курьер 7"];
    const values = Array(couriers.length).fill(23);
    const salaries = Array(couriers.length).fill(10350);
    const sales = Array(couriers.length).fill(55500);
    const expenses = Array(couriers.length).fill(800);
    const totals = Array(couriers.length).fill(44350);

    return (
        <>
            <div className="main-container">
                <header className="header">Дата</header>
                <section className="orders">
                    {couriers.map((value, index) => (
                        <CourierInfo key={index} courierName={value}/>
                    ))}
                </section>
                <section className="orders">
                    {values.map((value, index) => (
                        <OrderInfo key={index} orderCount={value}/>
                    ))}
                </section>
                <section className="salaries">
                    {salaries.map((salary, index) => (
                        <SalaryInfo key={index} salary={salary}/>
                    ))}
                </section>
                <section className="sales">
                    {sales.map((amount, index) => (
                        <SalesInfo key={index} salesAmount={amount}/>
                    ))}
                </section>
                <section className="expenses">
                    {expenses.map((amount, index) => (
                        <ExpenseInfo key={index} expenseAmount={amount}/>
                    ))}
                </section>
                <section className="totals">
                    {totals.map((amount, index) => (
                        <TotalInfo key={index} totalAmount={amount}/>
                    ))}
                </section>
            </div>
            <style jsx>{`
                .main-container {
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 80px;
                    font-size: 12px;
          color: #000;
        }
        .header {
          background-color: #b5b5b5;
          width: 100%;
          text-align: center;
          padding: 36px 60px;
          font: 600 32px/25% Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .header {
            padding: 0 20px;
          }
        }
        .couriers, .orders, .salaries, .sales, .expenses, .totals {
          display: flex;
          width: 100%;
          max-width: 1392px;
          gap: 8px;
          padding: 0 20px;
          font-weight: 400;
        }
        @media (max-width: 991px) {
          .couriers, .orders, .salaries, .sales, .expenses, .totals {
            flex-wrap: wrap;
          }
        }
        .couriers {
          color: #fff;
          font-weight: 600;
          text-align: center;
          line-height: 67%;
        }
        .courier-info {
            margin-top: 40px;
          font-family: Montserrat, sans-serif;
          border: 1px solid #000;
          background-color: #8f8f8f;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px 69px;
        }
        @media (max-width: 991px) {
          .courier-info {
            padding: 0 20px;
          }
        }
        .info-wrapper {
          display: flex;
          flex: 1;
          gap: 0;
        }
        .info-title, .total-title {
          font-family: Montserrat, sans-serif;
          border: 1px solid #000;
          background-color: #fff;
          justify-content: center;
          padding: 4px 0;
        }
        .info-content, .total-content {
          font-family: Montserrat, sans-serif;
          border: 1px solid #000;
          background-color: #fff;
          text-align: center;
          justify-content: center;
          padding: 12px 41px;
        }
      `}</style>
        </>
    );
}

export default MyComponent;
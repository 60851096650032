import React, { useContext } from "react";
import StuffButton from "./StuffButton";
import { Container } from "react-bootstrap";

const StuffDescription = ({
  stuff,
  quantity,
  addToQuantity,
  removeFromQuantity,
  updateQty
}) => {
  return (
    <Container fluid className="stuff-description">
      <Container fluid className="pt-4 px-0">
        {stuff.category === "snus" && (
          <>
            <span className="text-description text-description-header">
              {stuff.title} {stuff.description}
            </span>
            <span className="text-description">
              Вкус: <span className="text-grey">{stuff.flavor}</span>
            </span>
            <span className="text-description">
              Тип:{" "}
              <span className="text-grey">
                {stuff.isTobacco ? "табачный" : "бестабачный"} снюс
              </span>
            </span>
            <span className="text-description">
              Содержание никотина:{" "}
              <span className="text-grey">{stuff.nicotineContent} мг/г</span>
            </span>
            <span className="text-description">
              Количество порций:{" "}
              <span className="text-grey">{stuff.numberOfUses}</span>
            </span>
            <StuffButton
              quantity={quantity}
              addToQuantity={addToQuantity}
              removeFromQuantity={removeFromQuantity}
              stuff={stuff}
              setQuantity={updateQty}
            />
          </>
        )}
        {stuff.category === "pods" && (
          <>
            <span className="text-description text-description-header">
              {stuff.title} {stuff.description}
            </span>
            <span className="text-description">
              Вкус: <span className="text-grey">{stuff.flavor}</span>
            </span>
            <span className="text-description">
              Количество затяжек:{" "}
              <span className="text-grey">{stuff.numberOfUses}</span>
            </span>
            <span className="text-description">
              Содержание солевого никотина:{" "}
              <span className="text-grey">{stuff.nicotineContent}%</span>
            </span>
            <span className="text-description">
              Тип устройства: <span className="text-grey">{stuff.type}</span>
            </span>
            <StuffButton
              quantity={quantity}
              addToQuantity={addToQuantity}
              removeFromQuantity={removeFromQuantity}
              stuff={stuff}
              setQuantity={updateQty}
            />
          </>
        )}
        {stuff.category === "liquids" && (
          <>
            <span className="text-description text-description-header">
              {stuff.title} {stuff.description}
            </span>
            <span className="text-description">
              Вкус: <span className="text-grey">{stuff.flavor}</span>
            </span>
            <span className="text-description">
              Объем:{" "}
              <span className="text-grey">{stuff.liquidCapacity} мл</span>
            </span>
            <span className="text-description">
              Содержание никотина:{" "}
              <span className="text-grey">{stuff.nicotineContent}%</span>
            </span>
            <StuffButton
              quantity={quantity}
              addToQuantity={addToQuantity}
              removeFromQuantity={removeFromQuantity}
              stuff={stuff}
              setQuantity={updateQty}
            />
          </>
        )}
        {stuff.category === "devices" && (
          <>
            <span className="text-description text-description-header">
              {stuff.title} {stuff.description}
            </span>
            {stuff.color && (
              <span className="text-description">
                Цвет устройства:{" "}
                <span className="text-grey">{stuff.color}</span>
              </span>
            )}
            {stuff.batteryCapacity && (
              <span className="text-description">
                Емкость аккумулятора:{" "}
                <span className="text-grey">{stuff.batteryCapacity} мАч</span>
              </span>
            )}
            {stuff.cartridgeCapacity && (
              <span className="text-description">
                Объем бака:{" "}
                <span className="text-grey">{stuff.cartridgeCapacity} мл</span>
              </span>
            )}
            {stuff.flavor && (
              <span className="text-description">
                Вкус: <span className="text-grey">{stuff.flavor}</span>
              </span>
            )}
            {stuff.numberOfUses && (
              <span className="text-description">
                Количество затяжек:{" "}
                <span className="text-grey">{stuff.numberOfUses}</span>
              </span>
            )}
            {stuff.nicotineContent && (
              <span className="text-description">
                Содержание солевого никотина:{" "}
                <span className="text-grey">{stuff.nicotineContent}%</span>
              </span>
            )}
            {stuff.type && (
              <span className="text-description">
                Тип устройства: <span className="text-grey">{stuff.type}</span>
              </span>
            )}
            {stuff.configuration && (
              <span className="text-description">
                Комплектация:{" "}
                <span className="text-grey">{stuff.configuration}</span>
              </span>
            )}
            {stuff.maxPower && (
              <span className="text-description">
                Максимальная мощность:{" "}
                <span className="text-grey">{stuff.maxPower} Вт</span>
              </span>
            )}
            {stuff.coilType && (
              <span className="text-description">
                Тип испарителя:{" "}
                <span className="text-grey">{stuff.coilType}</span>
              </span>
            )}
            {stuff.resistance && (
              <span className="text-description">
                Сопротивление:{" "}
                <span className="text-grey">{stuff.resistance} Ом</span>
              </span>
            )}
            {stuff.compatibility && (
              <span className="text-description">
                Совместимость:{" "}
                <span className="text-grey">{stuff.compatibility}</span>
              </span>
            )}
            <StuffButton
              quantity={quantity}
              addToQuantity={addToQuantity}
              removeFromQuantity={removeFromQuantity}
              stuff={stuff}
              setQuantity={updateQty}
            />
          </>
        )}
      </Container>
    </Container>
  );
};

export default StuffDescription;

import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HistoryContext } from "../../../Context/HistoryContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImg from "../../../images/placeholder.jpg";
import "./stuff-card.css";
import { CartContext } from "../../../Context/CartContext";
import likeSvg from "../../../images/icons/Like.svg";
import fetchData from "../../../Lib/fetchData";
import likeActiveSvg from "../../../images/icons/LikeActive.svg";
const StuffCard = ({ stuff, isFeatured }) => {
  const { setHistoryPath } = useContext(HistoryContext);
  const navigate = useNavigate();
  const { dispatch, isInCart, getQuantity } = useContext(CartContext);
  const [featured, setFeatured] = useState(isFeatured);
  const [quantity, setQuantity] = useState(getQuantity(stuff._id));

  console.log(quantity);
  const addToFeatured = async () => {
    setFeatured(true);
    await fetchData(`/stuff/featured/${stuff._id}`, "PATCH");
  };

  const removeFromFeatured = async () => {
    setFeatured(false);
    await fetchData(`/stuff/featured/${stuff._id}`, "DELETE");
  };

  return (
    <div
      className={`stuff-card z-index-1 ${
        stuff.notAvailable ? "not-available-card" : ""
      }`}
      style={{ cursor: "pointer" }}
    >
      <div className="upper-div position-relative">
        <div className="stuff-card-img-div">
          <LazyLoadImage
            alt="stuff-img"
            src={`${process.env.REACT_APP_AWS_URL}/images/${stuff.image}`}
            className="stuff-card-img"
            width={"100%"}
            height={"100%"}
            placeholderSrc={placeholderImg}
            onClick={() => {
              if (!stuff.notAvailable) {
                localStorage.setItem(
                  "path",
                  window.location.pathname + window.location.search
                );
                navigate("/stuff?id=" + stuff._id);
              }
            }}
          />
          {featured ? (
            <div
              className="position-absolute top-0 end-0 mt-3 me-3 z-index-2"
              onClick={removeFromFeatured}
            >
              <img src={likeActiveSvg} alt="like" width={16} height={16} />
            </div>
          ) : (
            <div
              className="position-absolute top-0 end-0 mt-3 me-3 z-index-2"
              onClick={addToFeatured}
            >
              <img src={likeSvg} alt="like" width={16} height={16} />
            </div>
          )}
        </div>
        <div
          className="stuff-card-title"
          onClick={() => {
            if (!stuff.notAvailable) {
              setHistoryPath(window.location.pathname + window.location.search);
              navigate("/stuff?id=" + stuff._id);
            }
          }}
        >
          {stuff.title}
        </div>
      </div>
      {stuff.description && (
        <div className="stuff-card-desc">{stuff.description}</div>
      )}
      <div className="bottom-div">
        <div
          className="stuff-card-prices"
          onClick={() => {
            if (!stuff.notAvailable) {
              setHistoryPath(window.location.pathname + window.location.search);
              navigate("/stuff?id=" + stuff._id);
            }
          }}
        >
          {stuff.notAvailable ? (
            <p>Ожидается поступление</p>
          ) : (
            <>
              <p>
                От 1 шт −{" "}
                <span
                  className={`${
                    stuff.isDiscountPrice ? "discountPriceColor" : ""
                  }`}
                >
                  {stuff.priceFromOne} ₽
                </span>
              </p>
              <p>
                От 5 шт −{" "}
                <span
                  className={`${
                    stuff.isDiscountPrice ? "discountPriceColor" : ""
                  }`}
                >
                  {stuff.priceFromFive} ₽
                </span>
              </p>
              <p>
                От 10 шт −{" "}
                <span
                  className={`${
                    stuff.isDiscountPrice ? "discountPriceColor" : ""
                  }`}
                >
                  {stuff.priceFromTen} ₽
                </span>
              </p>
            </>
          )}
        </div>
        {isInCart(stuff._id) ? (
          <div className="button-change-card text-white">
            <i
              className="bi bi-dash-lg"
              onClick={() => {
                if (!stuff.notAvailable) {
                  dispatch({ payload: stuff, type: "remove" });
                  setQuantity(prevQty => --prevQty);
                }
              }}
            ></i>
            <input
              type="number"
              value={Number(quantity).toString()}
              onBlur={() => {
                if(Number(quantity) === 0)
                  dispatch({ payload: stuff, type: "remove" });
              }}
              onChange={(e) => {
                if (quantity === 0) setQuantity(Number(e.target.value));
                if (!isNaN(Number(e.target.value))) {
                  setQuantity(Number(e.target.value))
                  dispatch({
                    payload: {...stuff, quantity: Number(e.target.value)},
                    type: "update_quantity",
                  });
                }
                else {
                  setQuantity(0)
                  dispatch({
                    payload: { ...stuff, quantity: 0 },
                    type: "update_quantity",
                  });
                }
              }}
              style={{
                width: "30px",
                background: "none",
                outline: "none",
                border: "none",
                textAlign: "center",
                color: "white"
              }}
            />
            <i
              className="bi bi-plus-lg"
              onClick={() => {
                if (!stuff.notAvailable) {
                  dispatch({ payload: stuff, type: "add" });
                  setQuantity(prevQty => ++prevQty)
                }

              }}
            ></i>
          </div>
        ) : (
          <button
            className="stuff-card-button"
            onClick={() => {
              if (!stuff.notAvailable) {
                setQuantity(prevQty => ++prevQty);
                dispatch({ payload: { ...stuff, quantity: 1 }, type: "add" });
              }
            }}
          >
            В корзину
          </button>
        )}
      </div>
    </div>
  );
};

export default StuffCard;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const GetSwiper = ({
  category,
  filter,
  setActFilter,
  brands,
  activeBrand,
  setActBrand,
  models,
  setActModel,
  activeModel,
  isThirdFilter,
  thirdFilters,
  activeThirdFilter,
  setActThirdFilter,
  loading,
  isDevices,
  changeDevices,
  devicesModel,
  changeDevicesModel
}) => {
  if (loading) {
    return (
      <Swiper spaceBetween={8} slidesPerView={"auto"}>
        <SwiperSlide className="category-shop-filter">
          <div
            className={`category-filter ${
              filter === "all" ? "active-filter" : null
            }`}
            onClick={() => setActFilter("all")}
            style={{ cursor: "pointer" }}
          >
            {category === "snus" ? "Все товары" : "Все бренды"}
          </div>
        </SwiperSlide>
      </Swiper>
    );
  }

  if (category.toLowerCase() === "snus") {
    return (
      <>
        <Swiper spaceBetween={8} slidesPerView={"auto"}>
          <SwiperSlide className="category-shop-filter">
            <div
              className={`category-filter ${
                filter === "all" ? "active-filter" : null
              }`}
              onClick={() => setActFilter("all")}
              style={{ cursor: "pointer" }}
            >
              Все товары
            </div>
          </SwiperSlide>
          <SwiperSlide className="category-shop-filter">
            <div
              className={`category-filter ${
                filter === "tobacco" ? "active-filter" : null
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => setActFilter("tobacco")}
            >
              Табачный
            </div>
          </SwiperSlide>
          <SwiperSlide className="category-shop-filter">
            <div
              className={`category-filter ${
                filter === "non-tobacco" ? "active-filter" : null
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => setActFilter("non-tobacco")}
            >
              Бестабачный
            </div>
          </SwiperSlide>
        </Swiper>
        {filter !== "all" && (
          <Swiper spaceBetween={8} slidesPerView={"auto"} className="mt-2">
            <SwiperSlide className="category-shop-filter">
              <div
                className={`category-filter ${
                  activeBrand === "all" ? "active-filter" : null
                }`}
                onClick={() => setActBrand("all")}
                style={{ cursor: "pointer" }}
              >
                Все бренды
              </div>
            </SwiperSlide>

            {brands.map((brand) => (
              <SwiperSlide className="category-shop-filter" key={brand}>
                <div
                  className={`category-filter ${
                    activeBrand === brand ? "active-filter" : null
                  }`}
                  onClick={() => setActBrand(brand)}
                  style={{ cursor: "pointer" }}
                >
                  {brand}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {isThirdFilter && (
          <Swiper spaceBetween={8} slidesPerView={"auto"} className="mt-2">
            {thirdFilters.map((thirdFilter, index) => (
              <SwiperSlide className="category-shop-filter" key={index}>
                <div
                  className={`category-filter ${
                    thirdFilter.name === activeThirdFilter.name
                      ? "active-filter"
                      : null
                  }`}
                  onClick={() => setActThirdFilter(thirdFilter)}
                  style={{ cursor: "pointer" }}
                >
                  {thirdFilter.name}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </>
    );
  } else if (category.toLowerCase() === "devices") {
    return (
      <>
        <Swiper spaceBetween={8} slidesPerView={"auto"} className="mt-2">
          <SwiperSlide className="category-shop-filter">
            <div
              className={`category-filter ${
                activeBrand === "all" ? "active-filter" : null
              }`}
              onClick={() => setActBrand("all")}
              style={{ cursor: "pointer" }}
            >
              Все бренды
            </div>
          </SwiperSlide>

          {brands.map((brand) => (
            <SwiperSlide className="category-shop-filter" key={brand}>
              <div
                className={`category-filter ${
                  activeBrand === brand ? "active-filter" : null
                }`}
                onClick={() => setActBrand(brand)}
                style={{ cursor: "pointer" }}
              >
                {brand}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {activeBrand !== "all" && (
          <Swiper spaceBetween={8} slidesPerView={"auto"} className="mt-2">
            <SwiperSlide className="category-shop-filter">
              <div
                className={`category-filter ${
                  isDevices === 'all' ? "active-filter" : null
                }`}
                onClick={() => changeDevices('all')}
                style={{ cursor: "pointer" }}
              >
                Все товары
              </div>
            </SwiperSlide>
            <SwiperSlide className="category-shop-filter">
              <div
                className={`category-filter ${
                  isDevices === true ? "active-filter" : null
                }`}
                onClick={() => changeDevices(true)}
                style={{ cursor: "pointer" }}
              >
                Устройства
              </div>
            </SwiperSlide>
            <SwiperSlide className="category-shop-filter">
              <div
                className={`category-filter ${
                  isDevices === false ? "active-filter" : null
                }`}
                onClick={() => changeDevices(false)}
                style={{ cursor: "pointer" }}
              >
                Испарители
              </div>
            </SwiperSlide>
          </Swiper>
        )}


        {activeBrand !== "all" && isDevices !== 'all' && (
          <Swiper spaceBetween={8} slidesPerView={"auto"} className="mt-2">
            <SwiperSlide className="category-shop-filter">
              <div
                className={`category-filter ${
                  devicesModel === "all" ? "active-filter" : null
                }`}
                onClick={() => changeDevicesModel("all")}
                style={{ cursor: "pointer" }}
              >
                Все линейки
              </div>
            </SwiperSlide>
            {models.map((model) => (
              <SwiperSlide className="category-shop-filter" key={model}>
                <div
                  className={`category-filter ${
                    devicesModel === model ? "active-filter" : null
                  }`}
                  onClick={() => changeDevicesModel(model)}
                  style={{ cursor: "pointer" }}
                >
                  {model}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </>
    );
  } else {
    return (
      <>
        <Swiper spaceBetween={8} slidesPerView={"auto"} className="mt-2">
          <SwiperSlide className="category-shop-filter">
            <div
              className={`category-filter ${
                activeBrand === "all" ? "active-filter" : null
              }`}
              onClick={() => setActBrand("all")}
              style={{ cursor: "pointer" }}
            >
              Все бренды
            </div>
          </SwiperSlide>

          {brands.map((brand) => (
            <SwiperSlide className="category-shop-filter" key={brand}>
              <div
                className={`category-filter ${
                  activeBrand === brand ? "active-filter" : null
                }`}
                onClick={() => setActBrand(brand)}
                style={{ cursor: "pointer" }}
              >
                {brand}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {activeBrand !== "all" && (
          <Swiper spaceBetween={8} slidesPerView={"auto"} className="mt-2">
            <SwiperSlide className="category-shop-filter">
              <div
                className={`category-filter ${
                  activeModel === "all" ? "active-filter" : null
                }`}
                onClick={() => setActModel("all")}
                style={{ cursor: "pointer" }}
              >
                Все линейки
              </div>
            </SwiperSlide>
            {models.map((model) => (
              <SwiperSlide className="category-shop-filter" key={model}>
                <div
                  className={`category-filter ${
                    activeModel === model ? "active-filter" : null
                  }`}
                  onClick={() => setActModel(model)}
                  style={{ cursor: "pointer" }}
                >
                  {model}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </>
    );
  }
};

export default GetSwiper;

import React, {useCallback, useEffect, useState} from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import fetchData from "../../Lib/fetchData";

const AdminSnusBanner = () => {
  const [file, setFile] = useState();
  const [stuffIds, setStuffIds] = useState("");

  const notify = useCallback((msg) => toast(msg), []);
  const notifyError = useCallback((msg) => toast.error(msg), []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    const getBannerData = async () => {
      const res = await fetchData("/banner/64a591f8b7ecdcf76c82e8a4", "GET");

      setStuffIds((await res.json()).participatedStuff.map(v => v._id).join(" "))
    }

    getBannerData();
  }, []);

  const changeBanner = async () => {
    if (file) {
      const baseUrl = process.env.REACT_APP_SERVER_URL;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("entity", "banner");

      const data = await fetch(baseUrl + "/media/64a591f8b7ecdcf76c82e8a4", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adminToken"),
        },
      });

      if (data.status === 201) {
        notify("Баннер был изменён");
      } else {
        notifyError((await data.json()).message);
      }
    }

    if (stuffIds !== "") {
      const data = await fetchData(
        "/admin/banner/64a591f8b7ecdcf76c82e8a4",
        "PATCH",
        stuffIds.split(" "),
        true
      );
      if (data.status === 200) {
        notify("Товары в баннер успешно добавлены");
      } else {
        notifyError((await data.json()).message);
      }
    }
  };

  return (
    <div className="mx-4 my-4">
      <p className="h3">Редактирование баннера новинок снюса</p>
      <p>Вставьте айди товаров разделенных между собой пробелами</p>
      <input
        type="file"
        onChange={handleFileChange}
        className="form-control mb-2 w-auto"
      />
      <textarea
        value={stuffIds}
        onChange={(e) => setStuffIds(e.target.value)}
        className="w-75"
      />
      <p>
        <Button variant="primary" onClick={() => changeBanner()}>
          Добавить
        </Button>
      </p>
      <ToastContainer />
    </div>
  );
};

export default AdminSnusBanner;

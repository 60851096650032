import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import fetchData from "../../Lib/fetchData";
import OrderRow from "../../Components/Admin/OrderRow";
import { toast, ToastContainer } from "react-toastify";
import { socket } from '../../Lib/socket';
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

let dict = {
  all: "Все заказы",
  processing: "В обработке",
  active: "Принят",
  out_for_delivery: "В доставке",
  delivered: "Доставлен",
  cancelled: "Отменён",
};

const AdminOrders = () => {
  const baseUrl = process.env.REACT_APP_SERVER_URL;
  const [orders, setOrders] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [statusFilter, setStatusFilter] = useState('all');
  const [id, setId] = useState("");
  const audio = new Audio('/notification.mp3')

  const playNotification = () => {
    audio.play();
  }

  const getUserOrders = async() => {
    setOrders(prevState => prevState.filter(order => order.userId === parseInt(userId)));
  };

  const getUserOrdersByUsername = async () => {
    setOrders(prevState => prevState.filter(order => order.username === username));
  }

  const getUserOrderById = async () => {
    setOrders(prevState => prevState.filter(order => order._id.slice(
      order._id.length - 6
    ) === id));
  }

  useEffect(() => {
    const start = async () => {
      const res = await fetchData("/admin/orders", "GET", {}, true);
      setOrders((await res.json()).reverse());
    };

    socket.connect();

    const onConnect = () => {
      console.log('connected');
    }

    const newOrder = async (orderId) => {
      const data = await fetchData('/admin/orders/' + orderId, 'GET', {}, true).then(res => res.json());

      setOrders((prevState) => ([
        data,
        ...prevState,
      ]));

      playNotification();
    }

    socket.connect('https://ngsserver.onrender.com/')
    socket.on('connect', onConnect);

    socket.on('newOrder', newOrder);

    start();
  }, []);
  return (
    <>
    <div className="text-center p-3">
      <p className="h3">Просмотр заказов</p>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Username" aria-label="Username"
               aria-describedby="basic-addon2" onChange={e => setUsername(e.target.value)} />
        <div className="input-group-append">
          <button className="btn btn-primary" type="button" onClick={getUserOrdersByUsername}>Поиск</button>
        </div>
      </div>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="User ID" aria-label="User ID"
               aria-describedby="basic-addon2" onChange={e => setUserId(e.target.value)} />
        <div className="input-group-append">
          <button className="btn btn-primary" type="button" onClick={getUserOrders}>Поиск</button>
        </div>
      </div>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Номер заказа" aria-label="ID"
               aria-describedby="basic-addon2" onChange={e => setId(e.target.value)} />
        <div className="input-group-append">
          <button className="btn btn-primary" type="button" onClick={getUserOrderById}>Поиск</button>
        </div>
      </div>

      <div className="input-group mb-3">
        <select onChange={(e) => setStatusFilter(e.target.value)}>
          {Object.keys(dict).map((key) => (
            <option value={key} selected={key === statusFilter}>
              {dict[key]}
            </option>
          ))}
        </select>
      </div>

      <div className="p-2">
        <DateTimePicker onChange={setFromDate} value={fromDate} locale="ru" />
        <DateTimePicker onChange={setToDate} value={toDate} locale="ru" />
        <input type="password" placeholder="Пароль" value={password} onChange={e => setPassword(e.target.value)} />
        {fromDate !== null && toDate !== null && password !== "" && <a className="btn btn-primary" href={`${baseUrl}/admin/export-orders?pwd=${password}&fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}`}>Скачать</a>}
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>-</th>
            <th>Заказ</th>
          </tr>
        </thead>
        <tbody>
          {orders.length !== 0 &&
            orders
              .filter(order => order.status[order.status.length - 1].status === statusFilter || statusFilter === 'all')
              .map((order) => {
              const d = new Date(order.createdAt);
              const date =
                d.getDate() +
                "-" +
                (d.getMonth() + 1) +
                "-" +
                d.getFullYear() +
                " " +
                d.getHours() +
                ":" +
                d.getMinutes();

              return (
                <OrderRow
                  username={order.username || ""}
                  type={order.deliveryType}
                  userId={order.userId}
                  comment={order.comment || "-"}
                  date={date}
                  price={order.price.totalPrice}
                  priceUsd={order.price.totalPriceInUsd}
                  addr={order.address?.address || "-"}
                  phone={order.phoneNumber || "-"}
                  productsId={order.orderedStuff}
                  paymentType={order.paymentType || "-"}
                  pickUpLocation={order.pickUpLocation || "-"}
                  pickUpTime={order.pickUpTime || "-"}
                  fullName={order.address?.fullName || "-"}
                  postIndex={order.address?.postcode || "-"}
                  status={order.status[order.status.length - 1].status}
                  order={order._id}
                  notify={toast}
                />
              );
            })}
        </tbody>
      </Table>
      <ToastContainer />
    </div>
    </>
  );
};

export default AdminOrders;

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import fetchData from "../../Lib/fetchData";
import {Link, useNavigate} from "react-router-dom";

const OrderRow = ({
  order,
  type,
  userId,
  date,
  productsId,
  comment,
  phone,
  addr,
  price,
  pickUpLocation,
  pickUpTime,
  paymentType,
  fullName,
  postIndex,
  status,
  username,
  notify,
  priceUsd
}) => {
  let dict = {
    processing: "В обработке",
    active: "Принят",
    out_for_delivery: "В доставке",
    delivered: "Доставлен",
    cancelled: "Отменён",
  };

  let colors = {
    processing: "#ffb6b6",
    active: "#b6ccff",
    out_for_delivery: "#fffaa7",
    delivered: "#b6ffc0",
    cancelled: "#ff3333",
  };

  const [updatedStatus, setUpdatedStatus] = useState(status);
  const navigate = useNavigate();

  const saveStatus = async () => {
    const res = await fetchData(
      "/admin/orders/" + order,
      "PATCH",
      {
        status: updatedStatus,
      },
      true
    );
    if (res.status === 200) {
      notify("Статус товара успешно обновлён");
    } else {
      notify.error((await res.json()).message);
    }
  };

  const copyToClipboardDelivery = () => {
    const text = `----------------
${addr}

${productsId.map(
  (v) =>
    `${v.title} ${v.description != undefined ? `${v.description}` : ""} - ${
      v.quantity
    } шт.`
).join('\n')}

${phone}
Сумма ${price}
----------------
${comment}
`;

    navigator.clipboard.writeText(text);
    notify('Текст скопирован в буфер обмена')
  };

  const copyToClipboardPickup = () => {
    const text = `----------------
Самовывоз ${pickUpLocation}\n

${productsId.map(
      (v) =>
        `${v.title} ${v.description != undefined ? `${v.description}` : ""} - ${
          v.quantity
        } шт.`
    ).join('\n')}

${phone}
Сумма ${price}
----------------
${pickUpTime}
${comment}
`;

    navigator.clipboard.writeText(text);
    notify('Текст скопирован в буфер обмена')
  }

  const copyToClipboardPost = () => {
    const text = `----------------
Отправить почтой
Первый класс

${productsId.map(
      (v) =>
        `${v.title} ${v.description != undefined ? `${v.description}` : ""} - ${
          v.quantity
        } шт.`
    ).join('\n')}
  
Адрес:
${addr}

Получатель:
${fullName}

Контактный номер:
${phone}

Индекс: ${postIndex}
Сумма ${price}
----------------
`;

    navigator.clipboard.writeText(text);
    notify('Текст скопирован в буфер обмена')
  }

  if (type === "delivery") {
    return (
      <tr>
        <td style={{ background: colors[updatedStatus] }}></td>
        <td className="text-start" style={{ whiteSpace: "nowrap" }}>{date} {username} ({order.slice(
          order.length - 6
        )}) <Link to={`/admin/order/${order}`} style={{color: '#FF9400'}} >Подробнее</Link>
          <p>----------------</p>
          <p>{addr}</p>
          <br />
          <p>
            {productsId.map((v) => (
              <span>
                {`${v.title} ${
                  v.description != undefined ? `${v.description}` : ""
                } - ${v.quantity} шт.`}
                <br />
              </span>
            ))}
          </p>
          <br />
          <p>{phone}</p>
          <p>Сумма {price} {priceUsd ? `(${priceUsd})` : null}</p>
          <p>----------------</p>
          <p>{comment}</p>
          <select onChange={(e) => setUpdatedStatus(e.target.value)}>
            {Object.keys(dict).map((key) => (
              <option value={key} selected={key === status}>
                {dict[key]}
              </option>
            ))}
          </select>
          <Button variant="primary" style={{backgroundColor: '#FF9400', border: 'none', marginLeft: '8px'}} onClick={() => saveStatus()}>
            ✓
          </Button>
        </td>
      </tr>
    );
  } else if (type === "pick_up") {
    return (
      <tr>
        <td style={{ background: colors[updatedStatus] }}></td>
        <td className="text-start" style={{ whiteSpace: "nowrap" }}>{date} {username} ({order.slice(
          order.length - 6
        )}) <Link to={`/admin/order/${order}`} style={{color: '#FF9400'}} >Подробнее</Link>
          <p>----------------</p>
          <p>Самовывоз {pickUpLocation}</p>
          <br />
          <p>
            {productsId.map((v) => (
              <span>
                {`${v.title} ${
                  v.description != undefined ? `${v.description}` : ""
                } - ${v.quantity} шт.`}
                <br />
              </span>
            ))}
          </p>
          <br />
          <p>{phone}</p>
          <p>Сумма {price} {priceUsd ? `(${priceUsd})` : null}</p>
          <p>----------------</p>
          <p>{pickUpTime}</p>
          <p>{comment}</p>
          <select onChange={(e) => setUpdatedStatus(e.target.value)}>
            {Object.keys(dict).map((key) => (
              <option value={key} selected={key === status}>
                {dict[key]}
              </option>
            ))}
          </select>
          <Button variant="primary" style={{backgroundColor: '#FF9400', border: 'none', marginLeft: '8px'}} onClick={() => saveStatus()}>
            ✓
          </Button>
        </td>
      </tr>
    );
  } else if (type === "post") {
    return (
      <tr className="text-left">
        <td style={{ background: colors[updatedStatus] }}></td>
        <td className="text-start" style={{ whiteSpace: "nowrap" }}>{date} {username} ({order.slice(
          order.length - 6
        )}) <Link to={`/admin/order/${order}`} style={{color: '#FF9400'}} >Подробнее</Link>
          <p>----------------</p>
          <p>Отправить почтой</p>
          <p>Первый класс</p>
          <p>
            {productsId.map((v) => (
              <span>
                {`${v.title} ${
                  v.description != undefined ? `${v.description}` : ""
                } - ${v.quantity} шт.`}
                <br />
              </span>
            ))}
          </p>
          <br />
          <p>
            Адрес: <br />
            {addr}
          </p>
          <p>
            Получатель: <br />
            {fullName}
          </p>
          <p>
            Контактный номер: <br />
            {phone}
          </p>
          <p>Индекс: {postIndex}</p>
          <p>Сумма: {price} {priceUsd ? `(${priceUsd})` : null}</p>
          <p>----------------</p>
          <select onChange={(e) => setUpdatedStatus(e.target.value)}>
            {Object.keys(dict).map((key) => (
              <option value={key} selected={key === status}>
                {dict[key]}
              </option>
            ))}
          </select>
          <Button variant="primary" style={{backgroundColor: '#FF9400', border: 'none', marginLeft: '8px'}} onClick={() => saveStatus()}>
            ✓
          </Button>
        </td>
      </tr>
    );
  }
};

export default OrderRow;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import StuffCard from "./StuffCard/StuffCard";
import LazyLoad from "react-lazy-load";
import fetchData from "../../Lib/fetchData";

const StuffList = ({ stuffs, activeCategory, filter, brand, model, from }) => {
  const [featuredStuff, setFeaturedStuff] = useState(null);

  useEffect(() => {
    if (featuredStuff === null) {
      const getFeaturedStuff = async () => {
        const featuredStuff = await fetchData("/stuff/featured", "GET").then(
          (res) => res.json()
        );
        setFeaturedStuff(await featuredStuff.map((v) => v._id));
      };

      getFeaturedStuff();
    }
  }, []);

  if (featuredStuff === null) return null;

  return (
    <Container fluid>
      <Row className="gx-0">
        {stuffs.length > 0 ? (
          stuffs.map((stuff) => (
            <LazyLoad
              className="col-sm-6 col-md-4 col stuff-card-div"
              key={stuff._id}
            >
              <StuffCard
                stuff={stuff}
                isFeatured={featuredStuff.includes(stuff._id)}
              />
            </LazyLoad>
          ))
        ) : (
          <p className="text-center">Нет доступных товаров</p>
        )}
      </Row>
    </Container>
  );
};

export default StuffList;

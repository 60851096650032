import React, { createContext, useEffect, useReducer } from "react";

const CartContext = createContext({});

const initialCart =
  localStorage.getItem("cart") == null
    ? []
    : JSON.parse(localStorage.getItem("cart"));

const cartReducer = (state, action) => {
  switch (action.type) {
    case "add": {
      const i = state.findIndex((stuff) => stuff._id === action.payload._id);

      if (i > -1) {
        return state.map((stuff, index) =>
          index === i ? { ...stuff, quantity: stuff.quantity + 1 } : stuff
        );
      } else {
        return [...state, action.payload];
      }
    }

    case "remove": {
      return state
        .map((stuff) =>
          stuff._id === action.payload._id
            ? { ...stuff, quantity: stuff.quantity - 1 }
            : stuff
        )
        .filter((stuff) => stuff.quantity > 0);
    }

    case "update_quantity": {
      const i = state.findIndex((stuff) => stuff._id === action.payload._id);

      return state.map((stuff, index) =>
        index === i ? { ...stuff, quantity: action.payload.quantity } : stuff
      );
    }

    case "delete": {
      return state.filter((stuff) => stuff._id !== action.payload._id);
    }

    case "clear": {
      return [];
    }

    case "replace": {
      return action.payload;
    }
  }
};

const CartContextProvider = (props) => {
  const [cart, dispatch] = useReducer(cartReducer, initialCart);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const isInCart = (id) => {
    return cart.some((stuff) => stuff._id === id);
  };

  const getQuantity = (id) => {
    const i = cart.findIndex(stuff => stuff._id === id)
    return i === -1 ? 0 : cart[i].quantity;
  };

  return (
    <CartContext.Provider
      value={{
        cart: cart.sort((a, b) => a.title.localeCompare(b.title)),
        dispatch,
        isInCart,
        getQuantity,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export { CartContextProvider, CartContext };

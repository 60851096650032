import React from "react";

const StuffImage = ({ link }) => {
  return (
    <>
      <img
        src={link}
        className="stuff-image position-absolute top-50 start-50 translate-middle"
        alt="Stuff"
      />
    </>
  );
};

export default StuffImage;

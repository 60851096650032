import * as React from "react";

const OrderCard = ({ title, address, details, orderTime, username, orderNumber, imgSrc }) => (
    <article className="card">
        <header className="card-header">{title}</header>
        <div className="card-section">
            <p className="card-address">{address}</p>
            <p className="card-details" style={{
                whiteSpace: 'pre'
            }}>{details}</p>
            <footer className="card-footer">
                <p className="order-info">
                    {orderTime} {username} ({orderNumber}) <span className="more-info">Подробнее</span>
                </p>
                <img loading="lazy" src={imgSrc} alt="" className="order-img" />
            </footer>
        </div>
    </article>
);

function Shifts() {
    const orders = [
        {
            title: "Заказы в обработке",
            address: "Москва, Западный административный округ, район Кунцево, 18-й квартал, жилой комплекс Ярцевская 24",
            details: `----------------
Москва, Западный административный округ, район Кунцево, 18-й квартал, жилой комплекс Ярцевская 24
  
Oden's Cold Dry 16 gr - 10 шт.
  
+79652684224
Сумма 5250
----------------
-`,
            orderTime: "13:29 10 авг",
            username: "<username>",
            orderNumber: "<№ заказа>",
            imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/5d03112e96db92329bbf0933e0b9c4245cc51e6936e5e83eae198c544f939c1d?apiKey=04c91fccf0f3438da69361a283258b0f&",
        },
        // Repeat same structure for other orders
    ];

    return (
        <>
            <section className="main">
                <header className="main-header">Просмотр курьеров</header>
            </section>
            <div className="d-flex">
                <section className="courier-section">
                    <header className="courier-header">Курьер 1</header>
                    <div className="orders-grid">
                        <OrderCard
                            title="Заказы в обработке"
                            address={orders[0].address}
                            details={orders[0].details}
                            orderTime={orders[0].orderTime}
                            username={orders[0].username}
                            orderNumber={orders[0].orderNumber}
                            imgSrc={orders[0].imgSrc}
                        />
                        {/* Repeat OrderCard component for other orders */}
                    </div>
                </section>
                <section className="courier-section">
                    <header className="courier-header">Курьер 2</header>
                    <div className="orders-grid">
                        <OrderCard
                            title="Заказы в обработке"
                            address={orders[0].address}
                            details={orders[0].details}
                            orderTime={orders[0].orderTime}
                            username={orders[0].username}
                            orderNumber={orders[0].orderNumber}
                            imgSrc={orders[0].imgSrc}
                        />
                        {/* Repeat OrderCard component for other orders */}
                    </div>
                </section>
                <section className="courier-section">
                    <header className="courier-header">Курьер 3</header>
                    <div className="orders-grid">
                        <OrderCard
                            title="Заказы в обработке"
                            address={orders[0].address}
                            details={orders[0].details}
                            orderTime={orders[0].orderTime}
                            username={orders[0].username}
                            orderNumber={orders[0].orderNumber}
                            imgSrc={orders[0].imgSrc}
                        />
                        {/* Repeat OrderCard component for other orders */}
                    </div>
                </section>
            </div>
            <style jsx>{`
                .main {
                    background-color: #dadada;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: column;
                    color: #000;
                }

                .main-header {
                    background-color: #b5b5b5;
                    width: 100%;
                    text-align: center;
                    padding: 37px 60px;
                    font: 600 32px/25% Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
                }

                @media (max-width: 991px) {
                    .main-header {
                        max-width: 100%;
                        padding: 0 20px;
                    }
                }

                .orders-grid {
                    align-self: center;
                    display: flex;
                    margin-top: 61px;
                    gap: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    
                    padding: 0 20px;
                }

                @media (max-width: 991px) {
                    .orders-grid {
                        flex-wrap: wrap;
                        margin-top: 40px;
                    }
                }

                .card {
                    border-radius: 7px;
                    box-shadow: 0px 0px 1.7px 0px rgba(0, 0, 0, 0.2);
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding-bottom: 80px;
                }

                .card-header {
                    border-radius: 7px 7px 0 0;
                    background-color: #acbce8;
                    text-align: center;
                    padding: 14px 60px;
                    font: 600 12px/170% Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
                }

                @media (max-width: 991px) {
                    .card-header {
                        padding: 0 20px;
                    }
                }

                .card-section {
                    border-radius: 7px;
                    box-shadow: 0 0 1.7px 0 rgba(0, 0, 0, 0.2);
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    background-color: #fff;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                }

                .card-address {
                    font-family: Montserrat, sans-serif;
                    border-radius: 7px 7px 0 0;
                    border: 1px solid rgba(204, 204, 204, 1);
                    background-color: #acbce8;
                    padding: 9px 8px;
                }

                .card-details {
                    font-family: Montserrat, sans-serif;
                    margin-top: 14px;
                }

                .card-footer {
                    border-radius: 0 0 7px 7px;
                    border-top: 1px solid rgba(0, 0, 0, 0.2);
                    background-color: rgba(209, 209, 209, 0.2);
                    display: flex;
                    gap: 20px;
                    color: #ff5c00;
                    padding: 7px 8px;
                    margin-top: 14px;
                }

                .order-info {
                    font-family: Montserrat, sans-serif;
                    flex-grow: 1;
                }

                .order-img {
                    aspect-ratio: 1;
                    object-fit: auto;
                    width: 11px;
                    fill: var(--00-on-surface-disabled, rgba(0, 0, 0, 0.38));
                }

                .more-info {
                    font-size: 12px;
          color: rgba(255, 92, 0, 1);
        }
        .courier-section {
          border-radius: 7px;
          box-shadow: 0 0 1.7px 0 rgba(0, 0, 0, 0.2);
          background-color: #fff;
          display: flex;
          flex-direction: column;
          flex: 1;
          padding-bottom: 80px;
        }
        .courier-header {
          border-radius: 7px 7px 0 0;
          background-color: #b1fec7;
          text-align: center;
          padding: 14px 60px;
          font: 600 12px/170% Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          .courier-header {
            padding: 0 20px;
          }
        }
      `}</style>
        </>
    );
}

export { Shifts };
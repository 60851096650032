import React from "react";
import { Container } from "react-bootstrap";
import "./shop.css";
import Featured from "../../Components/Shop/Featured";
import ShopHeader from "../../Components/Shop/ShopHeader";
import Search from "../../Components/Shop/Search";
import SpecOffers from "../../Components/Shop/SpecOffers";
import CategoriesList from "../../Components/Shop/CategoriesList";
import OftenOrders from "../../Components/Shop/OftenOrders";
import Popular from "../../Components/Shop/Popular";
import AnchorBasket from "../../Components/Common/AnchorBasket";

const Shop = () => {
  return (
    <>
      <Container fluid className="px-3">
        <ShopHeader />
        <Search />
      </Container>
      <SpecOffers />
      <Container fluid className="px-3">
        <CategoriesList isFromShop={true} />
        <OftenOrders />
        <Popular />
        <Featured />
      </Container>
      <AnchorBasket />
    </>
  );
};

export default Shop;

import React, { useEffect, useState } from "react";
import fetchData from "../../Lib/fetchData";
import { Button, Table } from "react-bootstrap";
import StuffRow from "../../Components/Admin/StuffRow";
import { toast, ToastContainer } from "react-toastify";

let prevStuff = [];
const EditStuff = () => {
  const [filter, setFilter] = useState("");
  const [stuff, setStuff] = useState([]);
  const [currId, setCurrId] = useState(1);
  const notify = (msg) => toast.success(msg);

  useEffect(() => {
    const getStuff = async () => {
      const response = await fetchData(`/stuff?from=admin`, "GET", {}, true);

      const stuff = await response.json();
      setStuff(stuff);
      prevStuff = stuff;
    };

    getStuff();
  }, []);

  const filterStuff = () => {
    if (filter === "") {
      return setStuff(prevStuff);
    }
    setCurrId(1);
    setStuff(
      prevStuff.length !== 0 &&
        prevStuff.filter(
          (value) =>
            value.title.toLowerCase().startsWith(filter.toLowerCase()) ||
            value._id === filter.toLowerCase()
        )
    );
  };

  return (
    <>
      <div className="text-center p-3">
        <p className="h3">Редактирование товара</p>
        <div className="d-flex mb-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Фильтр"
            onKeyDown={e => {
              if(e.keyCode === 13) {
                e.preventDefault();

                filterStuff();
              }
            }}
          />
          <Button variant="primary" onClick={() => filterStuff()}>
            Поиск
          </Button>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Название</th>
              <th>Описание</th>
              <th>От 1 ед</th>
              <th>От 5 ед</th>
              <th>От 10 ед</th>
              <th>Скидка (градации)</th>
              <th>Выключить</th>
              <th>Потушить</th>
              <th>✓</th>
              <th>X</th>
            </tr>
          </thead>
          <tbody>
            {stuff.length > 0 &&
              stuff
                .slice((currId - 1) * 20, currId * 20)
                .map((v) => (
                  <StuffRow
                    key={v._id}
                    id={v._id}
                    title={v.title}
                    priceFromOne={v.priceFromOne}
                    priceFromFive={v.priceFromFive}
                    priceFromTen={v.priceFromTen}
                    description={v.description || ""}
                    discount={v.discount || 0}
                    disabled={v.disabled || false}
                    notAvailable={v.notAvailable || false}
                    isDiscPrices={v.isDiscountPrice || false}
                    category={v.category}
                    toast={notify}
                  />
                ))}
          </tbody>
        </Table>
        <select onChange={(e) => setCurrId(Number(e.target.value))}>
          {new Array(Math.floor(stuff.length / 20) + 1)
            .fill("")
            .map((_, i) => i + 1)
            .map((v) => (
              <option key={v} value={String(v)}>
                {v}
              </option>
            ))}
        </select>
      </div>
      <ToastContainer />
    </>
  );
};

export default EditStuff;

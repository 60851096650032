import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import fetchData from "../../Lib/fetchData";
import {Button, Table} from "react-bootstrap";

const AdminOrder = () => {
  const params = useParams()
  const [order, setOrder] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const getOrderInfo = async() => {
      const res = await fetchData("/admin/orders/" + params.id, "GET", {}, true);
      setOrder(await res.json());
    }

    getOrderInfo()
  }, []);


  if(Object.keys(order).length !== 0)
    return (
      <div className="p-4">
        <Button variant="primary" className="mb-3" onClick={() => navigate('/admin/orders')}>Назад</Button>
        <Table striped bordered>
          <thead>
          <tr>
            <th>-</th>
            <th>Информация</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            Телеграм ID
          </td>
          <td>
            {order.userId}
          </td>
        </tr>
        <tr>
          <td>
            Username
          </td>
          <td>
            {order.username || "-"}
          </td>
        </tr>
        <tr>
          <td>
            Дата
          </td>
          <td>
            { new Date(order.createdAt).toLocaleDateString() + " " + new Date(order.createdAt).toLocaleTimeString() }
          </td>
        </tr>
        <tr>
          <td>
            Данные
          </td>
          <td>
            {order.deliveryType === 'pick_up' && (
              <>
                <p>----------------</p>
                <p>Самовывоз {order.pickUpLocation}</p>
                <br />
                <p>
                  {order.orderedStuff.map((v) => (
                    <span>
                {`${v.title} ${
                  v.description != undefined ? `${v.description}` : ""
                } - ${v.quantity} шт.`}
                      <br />
              </span>
                  ))}
                </p>
                <br />
                <p>{order.phoneNumber}</p>
                <p>Сумма {order.price.totalPrice} {order.price.totalPriceInUsd ? `(${order.price.totalPriceInUsd})` : null}</p>
                <p>----------------</p>
                <p>{order.pickUpTime}</p>
                <p>{order.comment}</p>
              </>
            )}
            {
              order.deliveryType === 'post' && (
                <>
                  <p>----------------</p>
                  <p>Отправить почтой</p>
                  <p>Первый класс</p>
                  <p>
                    {order.orderedStuff.map((v) => (
                      <span>
                {`${v.title} ${
                  v.description != undefined ? `${v.description}` : ""
                } - ${v.quantity} шт.`}
                        <br />
              </span>
                    ))}
                  </p>
                  <br />
                  <p>
                    Адрес: <br />
                    {order.address?.address}
                  </p>
                  <p>
                    Получатель: <br />
                    {order.address?.fullName}
                  </p>
                  <p>
                    Контактный номер: <br />
                    {order.phoneNumber}
                  </p>
                  <p>Индекс: {order.address?.postcode}</p>
                  <p>Сумма: {order.price.totalPrice} {order.price.totalPriceInUsd ? `(${order.price.totalPriceInUsd})` : null}</p>
                  <p>----------------</p>
                </>
              )
            }
            {order.deliveryType === 'delivery' && (
              <>
                <p>----------------</p>
                <p>{order.address?.address}</p>
                <br />
                <p>
                  {order.orderedStuff.map((v) => (
                    <span>
                {`${v.title} ${
                  v.description != undefined ? `${v.description}` : ""
                } - ${v.quantity} шт.`}
                      <br />
              </span>
                  ))}
                </p>
                <br />
                <p>{order.phoneNumber}</p>
                <p>Сумма {order.price.totalPrice} {order.price.totalPriceInUsd ? `(${order.price.totalPriceInUsd})` : null}</p>
                <p>----------------</p>
                <p>{order.comment}</p>
              </>
            )}
          </td>
        </tr>
        <tr>
          <td>
            Список товаров
          </td>
          <td>
            {
              order.orderedStuff.map((v) => (
                <span>
                  {`${v.title} ${
                    v.description != undefined ? `${v.description}` : ""
                  } - ${v.quantity} шт.`}
                  <br />
                </span>
              ))
            }
          </td>
        </tr>
        <tr>
          <td>
            Комментарий
          </td>
          <td>
            {order.comment || "-"}
          </td>
        </tr>
        <tr>
          <td>
            Номер
          </td>
          <td>
            {order.phoneNumber || "-"}
          </td>
        </tr>
        <tr>
          <td>
            Адрес
          </td>
          <td>
            {order.address?.address || "-"}
          </td>
        </tr>
        <tr>
          <td>
            Местоположение
          </td>
          <td>
            {order.pickUpLocation || "-"}
          </td>
        </tr>
        <tr>
          <td>
            Время прибытия
          </td>
          <td>
            {order.pickUpTime || "-"}
          </td>
        </tr>
        <tr>
          <td>
            ФИО
          </td>
          <td>
            {order.address?.fullName || "-"}
          </td>
        </tr>
        <tr>
          <td>
            Индекс
          </td>
          <td>
            {order.address?.postcode || "-"}
          </td>
        </tr>
        <tr>
          <td>
            Цена заказа
          </td>
          <td>
            {order.price.totalPrice} {order.price.totalPriceInUsd ? `(${order.price.totalPriceInUsd})` : null}
          </td>
        </tr>
        <tr>
          <td>
            Тип оплаты
          </td>
          <td>
            {order.paymentType || "-"}
          </td>
        </tr>
        <tr>
          <td>
            Промокод
          </td>
          <td>
            {order.promocodeUsed || "-"}
          </td>
        </tr>
        </tbody>
        </Table>
      </div>
    );
};

export default AdminOrder;
import React, { useContext, useEffect, useState } from "react";
import fetchData from "../../Lib/fetchData";
import { UserContext } from "../../Context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import Shop from "../Shop/Shop";
import { QRCodeSVG } from "qrcode.react";
import Loader from "../Loaders/Loader";
import { Alert, Col, Container, Row } from "react-bootstrap";
import MainButton from "../../Components/Common/MainButton";
import { HistoryContext } from "../../Context/HistoryContext";

const tg = window.Telegram.WebApp;

const Index = () => {
  const UserCtx = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isTelegram, setIsTelegram] = useState(true);
  const [lostAccessToken, setLostAccessToken] = useState("");
  const [firstname, setFirstName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    tg.BackButton.show();
    tg.onEvent("viewportChanged", () => {
      if (!tg.isExpanded) {
        tg.expand();
      }
    });
    tg.BackButton.onClick(() => {
      switch (window.location.pathname) {
        case "/shop":
          tg.close();
          break;
        case "/":
          tg.close();
          break;
        case "/profile":
          navigate("/shop");
          break;
        case "/orders":
          navigate("/profile");
          break;
        case "/order":
          navigate("/orders");
          break;
        case "/addresses":
          navigate("/profile");
          break;
        case "/settings":
          navigate("/profile");
          break;
        case "/stuff":
          const path = localStorage.getItem("path");
          navigate(path);
          break;
        case "/categories":
          navigate("/shop");
          break;
        case "/cart":
          navigate(-1);
          break;
        case "/promotions":
          navigate("/shop");
          break;
        case "/novelties":
          navigate("/shop");
          break;
        case "/novelties-vapes":
          navigate("/shop");
          break;
        case "/delivery-terms":
          navigate("/shop");
          break;
        case "/order-created":
          navigate("/shop");
          break;
        case "/promocode":
          navigate("/shop");
          break;
      }
    });
    const authorize = async () => {
      const response = await fetchData("/auth/login", "POST", {
        tgInitData: tg.initData,
      });

      if (response.status === 200) {
        // Filling User Context
        UserCtx.setFirstname(response.firstName);
        UserCtx.setJwt(response.jwt);
        UserCtx.setUserId(response.userId);
        localStorage.setItem("jwt", (await response.json()).jwt);

        // Filling component state
        setIsTelegram(true);
        setIsNewUser(response.isNewUser);
        setFirstName(response.firstName);
        if (response.isNewUser) setLostAccessToken(response.lostAccessToken);
      } else {
        setIsTelegram(false);
      }
      setLoading(false);
    };

    authorize();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!isTelegram) {
    return (
      <Container fluid className="fs-1 text-center">
        <Alert variant="danger">Access denied</Alert>
      </Container>
    );
  }

  if (isNewUser) {
    return (
      <Container fluid className="position-relative new_user_block text-center">
        <Container
          fluid
          className="position-absolute top-50 start-50 translate-middle"
        >
          <Row>
            <Col className="new_user_ready">Готово!</Col>
          </Row>
          <Row>
            <Col>
              <QRCodeSVG
                className="qrcode-new-user"
                value={lostAccessToken}
                size="200"
              />
            </Col>
          </Row>
          <Container className="id_generated" fluid>
            <Row>
              <Col className="fs-5 px-0">
                Привет {firstname}, сделай скриншот!
              </Col>
            </Row>
            <Row>
              <Col className="fs-5 px-0">
                Он тебе понадобится в случае потери доступа к аккаунту.
              </Col>
            </Row>
          </Container>

          <Link to="shop">
            <MainButton
              text="перейти в магазин"
              width="80vw"
              bgColor="#FFFFFF"
              color="#FF9400"
              margin="30px 0 0 0"
            />
          </Link>
        </Container>
      </Container>
    );
  } else return <Shop />;
};

export default Index;

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

const PickupFields = ({
  pickupLocation,
  setPickupLocation,
  phone,
  setArriveTime,
  arriveTime,
  setPhone,
  comment,
  setComment,
  notifyError,
  changePromo,
  checkPromo,
  isPromo,
  togglePromo,
  isFreeDelivery,
  promo,
  totalQuantity,
  isPromoActivated,
}) => {
  return (
    <div className="delivery-hidden">
      <p className="delivery-hidden-header">Выберите местоположение</p>
      <Row className="deliveries-second">
        <Col>
          <button
            className={`delivery-type delivery-type-hidden bibirevo ${
              pickupLocation === "Бибирево" ? "bibirevo-active" : null
            }`}
            onClick={() => setPickupLocation("Бибирево")}
          >
              Бибирево
          </button>
        </Col>
        <Col>
          <button
            className={`delivery-type delivery-type-hidden davidkovo ${
              pickupLocation === "Давыдково" ? "davidkovo-active" : null
            }`}
            onClick={() => setPickupLocation("Давыдково")}
          >
            Давыдково
          </button>
        </Col>
        <Col>
          <button
            className={`delivery-type delivery-type-hidden kuzminki ${
              pickupLocation === "Бауманская" ? "kuzminki-active" : null
            }`}
            onClick={() => setPickupLocation("Бауманская")}
          >
            Бауманская
          </button>
        </Col>
      </Row>
      <div>
        <p className="mt-2 mb-2">
          Адрес точки самовывоза:{" "}
          {pickupLocation === "Бибирево" && "Москва, Бибиревская улица 4"}
          {pickupLocation === "Давыдково" && "Москва,улица Инициативная 9к2"}
          {pickupLocation === "Бауманская" && "Москва, Волховский переулок 2с2"}
        </p>
      </div>
      <div className="delivery-address">
        <input
          placeholder="Тел:"
          value={phone}
          onChange={(e) => setPhone(e.target.value.trim())}
          type="tel"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />

        <input
          type="text"
          disabled={pickupLocation === ""}
          placeholder={
            pickupLocation === ""
              ? "Выберите местоположение"
              : `Выберите время (${
                  pickupLocation === "Давыдково"
                    ? "11:00 - 00:00"
                    : "11:00 - 23:00"
                })`
          }
          value={arriveTime}
          onChange={(e) => setArriveTime(e.target.value)}
        />
        <input
          placeholder="Комментарий:"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />
        <p
          onClick={togglePromo}
          className="mt-2"
          style={{
            cursor: "pointer",
            paddingBottom: 0,
            marginBottom: 0,
            textDecoration: "underline",
          }}
        >
          Есть промокод?
        </p>
        <div
          className={`${
            isPromo ? "d-flex" : "d-none"
          } justify-content-between align-items-end gap-4`}
        >
          <input
            placeholder="Промокод:"
            className="w-50"
            onChange={(e) => {
              changePromo(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.target.blur();
              }
            }}
          />
          {promo !== "" && (
            <p
              className={`${
                isFreeDelivery && !isPromoActivated
                  ? totalQuantity >= 5
                    ? "text-success"
                    : "text-danger"
                  : "text-danger"
              } mb-0 pb-0`}
              style={{ fontSize: "13px" }}
            >
              {isFreeDelivery
                ? totalQuantity >= 5
                  ? !isPromoActivated
                    ? "Верный промокод"
                    : "Действует только на первый заказ"
                  : "Минимальное количество товаров - 5 шт"
                : isPromoActivated
                ? "Действует только на первый заказ"
                : "Неверный промокод"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PickupFields;

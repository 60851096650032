import React, { useEffect, useState } from "react";
import LeftArrow from "../Components/UI/LeftArrow";
import SpecialHeader from "../Components/Common/SpecialHeader/SpecialHeader";
import PromotionsIcon from "../images/imgs/PromotionsIcon.png";
import StuffList from "../Components/Common/StuffList";
import fetchData from "../Lib/fetchData";
import { useNavigate } from "react-router-dom";
import Loader3Packs from "./Loaders/Loader3Packs";

const Promotions = () => {
  const [promotionStuff, setPromotionStuff] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getPromotionStuff = async () => {
      const response = await fetchData(`/stuff/promotions`, "GET");

      if (response.status === 200) {
        const stuff = await response.json();
        setPromotionStuff(stuff);
        setLoading(false);
      }
    };

    getPromotionStuff();
  }, []);

  if (loading) {
    return <Loader3Packs />;
  }

  return (
    <div>
      <SpecialHeader
        title="Акции"
        gradientColor="linear-gradient(180deg, #00FF38 0%, #00FFE0 100%)"
        svg={PromotionsIcon}
      />
      <StuffList stuffs={promotionStuff} />
    </div>
  );
};

export default Promotions;

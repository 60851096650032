import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import LeftArrow from "../UI/LeftArrow";
import { Link, useNavigate } from "react-router-dom";
import CartSvg from "../UI/CartSvg/CartSvg";
import StuffImage from "./StuffImage";
import fetchData from "../../Lib/fetchData";
import likeActiveSvg from "../../images/icons/LikeActive.svg";
import likeSvg from "../../images/icons/Like.svg";

const StuffUpperBlock = ({ link, id }) => {
  const [isFeatured, setIsFeatured] = useState(false);
  const navigate = useNavigate();
  const addToFeatured = async () => {
    setIsFeatured(true);
    await fetchData(`/stuff/featured/${id}`, "PATCH");
  };

  const removeFromFeatured = async () => {
    setIsFeatured(false);
    await fetchData(`/stuff/featured/${id}`, "DELETE");
  };

  useEffect(() => {
    const updateIsFeatured = async () => {
      const featuredStuff = await fetchData("/stuff/featured", "GET")
        .then((res) => res.json())
        .then((data) => data.map((v) => v._id));

      setIsFeatured(featuredStuff.includes(id));
    };

    updateIsFeatured();
  }, []);
  return (
    <Container fluid className="stuff-image-container px-0 position-relative">
      <Link to="/cart">
        <div className="position-absolute top-0 end-0 cart-svg">
          <CartSvg />
        </div>
      </Link>
      <StuffImage link={link} />
      {isFeatured ? (
        <div
          className="position-absolute bottom-0 end-0 m-4"
          onClick={removeFromFeatured}
          style={{ cursor: "pointer" }}
        >
          <img src={likeActiveSvg} alt="like" width={32} height={32} />
        </div>
      ) : (
        <div
          className="position-absolute bottom-0 end-0 m-4"
          onClick={addToFeatured}
          style={{ cursor: "pointer" }}
        >
          <img src={likeSvg} alt="like" width={32} height={32} />
        </div>
      )}
    </Container>
  );
};

export default StuffUpperBlock;

import React, {useEffect, useState} from 'react';
import fetchData from "../../Lib/fetchData";

const AddressSelector = ({vis, option, setOption, setVis, setGeo}) => {
    const [addresses, setAddresses] = useState([]);
    const getAddresses = async () => {
        const addrs = await fetchData("/address", "GET").then((res) =>
            res.json()
        );
        setAddresses(addrs.reverse());

    };

    useEffect(() => {
        getAddresses();
    }, []);

    const deleteAddress = async (id) => {
        try {
            const res = await fetchData(`/address/${id}`,"DELETE", {}, false);

            getAddresses();
        } catch (error) {
            console.error('Error deleting address: ', error);
        }
    };

    let theme = {
        container: {
            width: '100%',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            fontFamily: 'Arial, sans-serif',
            padding: '10px'
        },
        header: {
            padding: '0.5rem',
            textAlign: 'center',
            borderBottom: '1px solid #F1F1F1',
        },
        icon: {
            fontSize: '1.5rem',
        },
        body: {
            padding: '1rem',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            padding: '1rem 0',
            borderBottom: '1px solid #F1F1F1',
            color: 'var(--text-color)'
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            color: 'var(--text-color)'
        },
        radio: {
            marginRight: '1rem',
        },
        text: {
            display: 'flex',
            flexDirection: 'column',
            color: 'var(--text-color)'
        },
        button: {
            width: '100%',
            padding: '0.5rem',
            border: 'none',
            borderRadius: '32px',
            cursor: 'pointer',
            textAlign: 'center',
            background: '#FF9400',
            margin: '20px 0 0 0',
            fontWeight: 'semibold',
            color: 'white'
        },
        address: {
            color: 'var(--text-color)'
        }
    };

    return (
        vis &&
        <div style={theme.container}>
            <div style={theme.body}>
                {addresses.filter(address => address.addressGeo != null).length > 0 ? addresses.filter(address => address.addressGeo != null).map((address, index) => (
                    <div key={index} style={theme.item} onClick={() => {
                        setOption(address.address)
                        setGeo(address.addressGeo.split(' ').map((el) => Number(el)))
                    }}>
                        <input type="radio" name="address" id={index} style={theme.radio}/>
                        <label htmlFor={index} style={theme.label}>
                            <img src="/addresses.svg" alt="" width={25} height={25}/>
                            <span style={theme.text}>
                            <span style={theme.address}>{address.address}</span>
                            <span style={{color: "#6c6c6c", fontSize: '0.875rem'}}>
                                {address.fullName}
                            </span>
                          </span>
                        </label>
                        <button
                            onClick={() => deleteAddress(address._id)}
                            style={{
                                width: 'fit-content',
                                height: '100%',
                                background: 'transparent',
                                border: 'none',
                                padding: 0,
                            }}
                        >
                            <img
                                src="/delete.png"
                                alt="Delete icon"
                                style={{
                                    position: 'relative',
                                    visibility: 'visible',
                                    width: '20px',
                                    height: '20px',
                                }}
                            />
                        </button>
                    </div>
                )) : <p>Нет доступных адресов</p>}
            </div>
            {addresses.filter(address => address.addressGeo != null).length > 0 &&
                <button style={theme.button} onClick={() => setVis(false)}>ВЫБРАТЬ</button>}
        </div>
    );
};

export default AddressSelector;
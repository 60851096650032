import React from "react";

const BrandPriority = ({ brand, brandPriority, changeBrandPriority }) => {
  return (
    <div className="mb-2">
      <span className="me-2">{brand}</span>
      <input
        type="text"
        className="form-control mb-2 w-auto d-inline"
        placeholder={brandPriority}
        onChange={(e) => changeBrandPriority(brand, Number(e.target.value))}
      />
    </div>
  );
};

export default BrandPriority;

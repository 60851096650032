import React from "react";
import loaderAnim from "../../animations/animation.json";
import Lottie from "lottie-react";
import "./loader.css";

const Loader = () => {
  return (
    <div className="position-relative div-loader">
      <div className="position-absolute bottom-0 start-0 lottie-div">
        <Lottie animationData={loaderAnim} loop={true} />
      </div>
    </div>
  );
};

export default Loader;

import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import fetchData from "../../Lib/fetchData";
const ProtectedRoute = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(true);
  useEffect(() => {
    const authorize = async () => {
      if (localStorage.getItem("adminToken") == null)
        return setIsAuthorized(false);
      try {
        const res = await fetchData("/admin/validate", "GET", {}, true);
        if (res.status === 200) {
          setIsAuthorized(true);
        }
        if (res.status === 401) {
          setIsAuthorized(false);
        }
      } catch (e) {
        if (e.response.status === 401) {
          setIsAuthorized(false);
        }
      }
    };

    authorize();
  }, []);

  return isAuthorized ? (
    { ...children }
  ) : (
    <Navigate to="/admin/login" replace />
  );
};

export default ProtectedRoute;

import React from "react";
import "./special-header.css";
const SpecialHeader = ({ title, svg, gradientColor }) => {
  return (
    <div
      className="spec-header position-relative"
      style={{
        background: gradientColor,
      }}
    >
      <p className="position-absolute bottom-0 start-0 spec-header-title">
        <img src={svg} alt="del-terms" />
        {title}
      </p>
    </div>
  );
};

export default SpecialHeader;

import React from "react";
import { Col, Row } from "react-bootstrap";
import fetchData from "../../Lib/fetchData";

const FlexElement = ({ text, description, svg, svgDelete, id, rerender }) => {
  const deleteAddress = async () => {
    try {
      const res = await fetchData(`/address/${id}`,"DELETE", {}, false);

      rerender()
    } catch (error) {
      console.error('Error deleting address: ', error);
    }
  };

  return (
      <Row className="profile-link px-0">
        <Col className="link-img-div col-2 text-center position-relative">
          <img
              src={svg}
              alt="Svg icon"
              className="link-img position-absolute top-50 start-50 translate-middle"
              width="20px"
              height="20px"
          />
        </Col>
        <Col className="link-text mx-0 px-0 col-9">
          <span className="link-text-header">{text}</span>
          {description && <span className="link-text-desc">{description}</span>}
        </Col>
        {svgDelete && (
            <button
                onClick={deleteAddress}
                style={{
                  width: 'fit-content',
                  height: '100%',
                  background: 'transparent',
                  border: 'none',
                  padding: 0,
                }}
            >
              <img
                  src={svgDelete}
                  alt="Delete icon"
                  style={{
                    position: 'relative',
                    visibility: 'visible',
                    width: '20px',
                    height: '20px',
                  }}
              />
            </button>
        )}
      </Row>

  );
};

export default FlexElement;
import React, { useEffect } from "react";
import SpecialHeader from "../../Components/Common/SpecialHeader/SpecialHeader";
import LeftArrow from "../../Components/UI/LeftArrow";
import DeliveryTermsIcon from "../../images/imgs/DeliveryTermsIcon.png";
import { Container } from "react-bootstrap";
import "./delivery-terms.css";
import { useNavigate } from "react-router-dom";

const DeliveryTerms = () => {
  const navigate = useNavigate();

  return (
    <div>
      <SpecialHeader
        title="Условия доставки"
        gradientColor="linear-gradient(360deg, #FFA800 0%, #FF5C00 100%)"
        svg={DeliveryTermsIcon}
      />
      <Container className="delivery-terms">
        <ul>
          <li>Доставка в пределах МКАД – 350 рублей</li>
          <li>Доставка за МКАД – 450 рублей</li>
          <li>
            Экспресс доставка Яндекс Go – стоимость зависит от тарифов Яндекс Go
          </li>
          <li>Доставка Почтой России – 450 рублей</li>
          <li>Самовывоз м. Давыдково, м. Бибирево, м. Бауманская</li>
        </ul>
      </Container>
      <div className="text-center mt-5 w-50 m-auto delivery-terms-grey mb-2">
        *Стоимость доставки может измениться, уточняйте у менеджера
      </div>
    </div>
  );
};

export default DeliveryTerms;

import React, { createContext, useState } from "react";

const UserContext = createContext({});

const UserContextProvider = (props) => {
  const [id, setId] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [jwtToken, setJwtToken] = useState("");

  const setUserId = (userId) => {
    setId(userId);
  };

  const setFirstname = (name) => {
    setFirstName(name);
  };

  const setJwt = (jwt) => {
    setJwtToken(jwt);
  };

  return (
    <UserContext.Provider
      value={{
        id,
        setUserId,
        firstName,
        setFirstname,
        jwtToken,
        setJwt,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };

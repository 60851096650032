const fetchData = async (endpoint, method, body = {}, admEndpoint = false) => {
  const baseUrl = process.env.REACT_APP_SERVER_URL;

  if (admEndpoint) {
    if (method === "GET") {
      const response = await fetch(baseUrl + endpoint, {
        method,
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("adminToken"),
        },
      }).catch((err) => console.log(err.message));

      return response;
    } else {
      const response = await fetch(baseUrl + endpoint, {
        method,
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("adminToken"),
        },
        body: JSON.stringify(body),
      }).catch((err) => console.log(err.message));

      return response;
    }
  } else {
    if (method === "GET") {
      const response = await fetch(baseUrl + endpoint, {
        method,
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
      }).catch((err) => console.log(err.message));

      return response;
    } else {
      const response = await fetch(baseUrl + endpoint, {
        method,
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(body),
      }).catch((err) => console.log(err.message));

      return response;
    }
  }

  return {};
};

export default fetchData;

import React, { useEffect, useState } from "react";
import fetchData from "../../Lib/fetchData";
import BrandPriority from "../../Components/Admin/BrandPriority";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import ModelPriority from "../../Components/Admin/ModelPriority";

const AdminModels = () => {
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);

  const changeModelPriority = (model, priority) => {
    const i = models.findIndex((modelToFind) => modelToFind.model === model);

    models[i].modelPriority = priority;
    setModels(models);
  };

  const saveModelsPriority = async () => {
    const res = await fetchData("/admin/models", "PATCH", models, true);
    if (res.status === 200) {
      toast("Последовательность брендов успешно изменена");
    } else {
      toast.error("Произошла ошибка");
    }
  };

  useEffect(() => {
    const getModels = async () => {
      const res = await fetchData(`/stuff/models/${category}/${brand}`, "GET");
      if (res.status === 200) {
        setModels(await res.json());
      }
    };

    if (category !== "" && brand !== "" && brand !== "X") getModels();
  }, [category, brand]);

  useEffect(() => {
    const getBrandsByCategory = async () => {
      const res = await fetchData("/stuff/brands/" + category, "GET", {}, true);
      if (res.status === 200) {
        const data = await res.json();
        setBrands(data.map((brand) => brand.brand));
      }
    };

    getBrandsByCategory();
  }, [category]);

  return (
    <>
      <div className="mx-4">
        <h1>Редактирование порядка моделей</h1>
        <select
          className="form-select mb-2 w-auto"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="category" selected>
            Категория
          </option>
          <option value="pods">Поды</option>
          <option value="liquids">Жижа</option>
          <option value="devices">Устройства</option>
        </select>
        {brands.length !== 0 && (
          <select
            className="form-select mb-2 w-auto"
            onChange={(e) => setBrand(e.target.value)}
          >
            <option value="X" selected>
              Выберите бренд
            </option>
            {brands.map((brand) => (
              <option value={`${brand}`} key={brand}>
                {brand}
              </option>
            ))}
          </select>
        )}
        <div>
          {models.map((model) => (
            <ModelPriority
              changeModelPriority={changeModelPriority}
              model={model.model}
              modelPriority={model.modelPriority}
              key={model.model}
            />
          ))}
        </div>
        <Button variant="primary" onClick={saveModelsPriority}>
          Сохранить
        </Button>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminModels;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import StuffCard from "../Common/StuffCard/StuffCard";
import fetchData from "../../Lib/fetchData";
import StuffList from "../Common/StuffList";

const Popular = () => {
  const [popularStuff, setPopularStuff] = useState([]);

  useEffect(() => {
    const getPopularStuff = async () => {
      const response = await fetchData(`/stuff/popular`, "GET");

      if (response.status === 200) {
        const stuff = await response.json();
        setPopularStuff(stuff.map((v) => v.stuff[0]));
      }
    };

    getPopularStuff();
  }, []);
  return (
    <div className="mt-4 pb-3">
      <p className="px-3 shop-header mb-1">Популярное</p>
      <Row>{<StuffList stuffs={popularStuff} />}</Row>
    </div>
  );
};

export default Popular;

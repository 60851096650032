import { useCallback, useContext, useEffect } from "react";
import ReactRouter from "./Router/ReactRouter";
import getTheme from "./Lib/getTheme";

const tg = window.Telegram.WebApp;

const App = () => {
  const setThemeClass = useCallback(() => {
    document.documentElement.className = tg.colorScheme;
  }, []);

  const initTelegram = useCallback(() => {
    tg.onEvent("themeChanged", setThemeClass);
    setThemeClass();
    getTheme(tg.colorScheme);
    tg.expand();
    tg.enableClosingConfirmation();
    tg.ready();
  }, []);

  useEffect(() => {
    initTelegram();
  }, []);

  return <ReactRouter />;
};

export default App;

import React from "react";
import { Col, Row } from "react-bootstrap";
import MainButton from "../Common/MainButton";

const CheckoutButton = ({ isEnabled }) => {
  return (
    <Row>
      <Col>
        <MainButton
          width="100%"
          text="Оформить заказ"
          color="white"
          bgColor={isEnabled ? "#FF9400" : "gray"}
          margin="0 0 0 0"
        />
      </Col>
    </Row>
  );
};

export default CheckoutButton;

import React from "react";
import ReactDOM from "react-dom/client";

import "./index.scss";
import App from "./App";
import { CartContextProvider } from "./Context/CartContext";
import { UserContextProvider } from "./Context/UserContext";

import "bootstrap/dist/js/bootstrap.bundle.min";
import { HistoryContextProvider } from "./Context/HistoryContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <UserContextProvider>
    <CartContextProvider>
      <HistoryContextProvider>
        <App />
      </HistoryContextProvider>
    </CartContextProvider>
  </UserContextProvider>
  // </React.StrictMode>
);
